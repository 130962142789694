import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getIndustryById,
  getOneAccountDetail,
  getproviderService,
} from "../../../redux/slices/web";
import { useWebSelector } from "../../../redux/selector/web";
import { decodeData, userRoleName, userRoles } from "../../../utilities/helper";
import FullPageloader from "../../../common/FullPageloader";

function AccountsDetailsView(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const webselector = useWebSelector();

  const getOneAccountInfo = webselector?.getOneAccountInfo;
  const services = webselector?.providerService?.services;
  //   const services = webselector?.providerService?.services;
  const serviceNames = services?.map((service) => service?.name);
  const availability = getOneAccountInfo[0]?.providerInfo?.availability;

  const [loading, setLoading] = useState(false);
  const decodeId = decodeData(id);

  // Fetch one user detail
  const OneUserDetail = () => {
    let params = {
      id: decodeId,
    };
    setLoading(true);
    dispatch(
      getOneAccountDetail({
        ...params,
        cb(res) {
          if (res?.data?.data[0].role && res?.data?.data[0].role == userRoles.provider) ProvidersService();
          setLoading(false);
        },
      })
    );
  };

  // Fetch provider service
  const ProvidersService = () => {
    let params = {
      userId: decodeId,
    };
    setLoading(true);
    dispatch(
      getproviderService({
        ...params,
        cb(res) {
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    OneUserDetail();
  }, []);

  return (
    <>
      {loading ? (
        <FullPageloader />
      ) : (
        <div className="content-wrapper adminUsers">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 headingMain">User Details</h1>
                </div>
              </div>
            </div>
          </div>
          <section className="addIndustrySection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <form>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label customLables">Name</label>
                        <input
                          type="text"
                          className="form-control customControl"
                          value={
                            getOneAccountInfo &&
                            getOneAccountInfo[0]?.userInfo?.name
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label customLables">Phone</label>
                        <input
                          type="text"
                          className="form-control customControl"
                          value={
                            getOneAccountInfo &&
                            getOneAccountInfo[0]?.userInfo?.phone
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label customLables">Email</label>
                        <input
                          type="text"
                          className="form-control customControl"
                          value={
                            getOneAccountInfo && getOneAccountInfo[0]?.email
                          }
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label customLables">
                          Status
                        </label>
                        <input
                          type="text"
                          className="form-control customControl"
                          value={
                            getOneAccountInfo && getOneAccountInfo[0]?.status
                          }
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label customLables">Role</label>
                        <input
                          type="text"
                          className="form-control customControl"
                          value={
                            getOneAccountInfo && userRoleName[getOneAccountInfo[0]?.role]
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    {getOneAccountInfo[0]?.role === "provider" && (
                      <>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label customLables">
                              Zip Code
                            </label>
                            <input
                              type="text"
                              className="form-control customControl"
                              value={
                                getOneAccountInfo[0]?.providerInfo?.zipCode
                                  ? getOneAccountInfo[0]?.providerInfo?.zipCode
                                  : "No ZipCode found"
                              }
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label customLables">
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control customControl"
                              value={
                                getOneAccountInfo[0]?.providerInfo?.companyName
                                  ? getOneAccountInfo[0]?.providerInfo
                                    ?.companyName
                                  : "No Company Name found"
                              }
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label customLables">Services</label>
                          {services && services.length > 0 ? (
                            services.map((service) => (
                              <div key={service._id} className="mb-3">
                                {/* <h4 className="form-label customLables">{service.name}</h4> */}
                                <input
                                  type="text"
                                  className="form-control customControl"
                                  value={
                                    service.name +
                                    (service.subServices && service.subServices.length > 0
                                      ? " -> " + service.subServices.map(subService => subService.name).join(", ")
                                      : " No Subservices")
                                  }
                                  readOnly
                                />
                              </div>

                            ))
                          ) : (
                            // <div>No Services Found</div>
                            <div>
                              <input
                                type="text"
                                className="form-control customControl"
                                value={
                                  "No services found"
                                }
                                readOnly
                              />
                            </div>

                          )}
                        </div>

                        <div className="row">
                          <label className="form-label customLables">
                            Availability
                          </label>
                          {availability && availability.length > 0 ? (
                            availability.map((item) => {
                              const daysOfWeek = [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                                "Sunday",
                              ];
                              const dayName = daysOfWeek[item.day - 1];
                              return (
                                <div key={item._id} className="mb-3">
                                  <input
                                    type="text"
                                    className="form-control customControl"
                                    value={`${dayName} ${item.startTime} - ${item.endTime}`}
                                    readOnly
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control customControl"
                                value="No availability"
                                readOnly
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default AccountsDetailsView;

