import React, { useEffect, useRef, useState } from 'react'
import { useTransactionSelector } from '../../redux/selector/transaction'
import { useDispatch } from 'react-redux'
import { updateTransactionShare } from '../../redux/slices/transaction'
import { toast } from 'react-toastify'
import { blockInvalidChar } from '../../utilities/helper'

export default function ProviderShareModify(props) {
  const { handleClose, refreshShare } = props

  const dispatch = useDispatch()

  const transactionSelector = useTransactionSelector()

  const [share, setShare] = useState("")
  const [isChanged, setIsChanged] = useState(false)


  const handleChange = (event) => {
    if (event.target.value.length > 3) {
      event.preventDefault();
      return;
    }
    setIsChanged(true)
    setShare(event.target.value)
  }
  useEffect(() => {
    if (transactionSelector.shareData) {
      setShare(transactionSelector.shareData.value)
    }
  }, [transactionSelector.shareData])

  const updateShare = () => {

    if (!transactionSelector?.shareData?._id) {
      toast.error("Something Went wrong")
      return;
    }

    const params = {
      id: transactionSelector?.shareData?._id,
      name: 'Provider Share',
      value: share
    }

    if (!share || share == "") {
      toast.error("Please enter a value of share")
    } else if (share && (share < 0 || share > 100)) {
      toast.error("Please enter valid value for share")
    }

    dispatch(updateTransactionShare({
      ...params,
      cb(res) {
        if (res.status === 200) {
          setIsChanged(false)
          handleClose && handleClose()
          refreshShare && refreshShare()
        }
      }
    }))

  }

  const priceInputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
    };
    const inputElement = priceInputRef.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <h5 className='mt-2'>Provider Share(%) </h5>
        </div>
        <div className="col-md-6">
          <input
            type="number"
            className="form-control customControl"
            placeholder="Enter Service Name"
            name="share"
            min={0}
            max={100}
            value={share}
            onKeyPress={blockInvalidChar}
            onChange={handleChange}
            ref={priceInputRef}
          />
        </div>
        <div className="col-md-12 mt-3">
          <div className="d-flex justify-content-center">
            <button
              className="cancelBtn me-4"
              type="button"
              onClick={(e) => {
                handleClose();
              }}
            >
              CANCEL
            </button>
            {isChanged &&
              <button className="addBtn" style={{ maxWidth: "187px" }} onClick={updateShare}>
                Update
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
