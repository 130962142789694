import React,{useEffect} from "react";
import { useAuthSelector } from "../../../../redux/selector/auth";
import * as Images from "../../../../utilities/images";
import { getTimeDiffOutput } from "../../../../utilities/helper";

export default function ListBlock(props) {
  const { info, key, activeConversation,getAllConversation } = props;
  const authSelector = useAuthSelector();
  let currentUser = authSelector.loggedInUser;
  let {
    participantsDetails,
    lastMessage,
    totalMessages,
    participants,
    _id,
    updatedAt,
  } = info;
  let nameArray = [];
  let imageArray = [];
  participantsDetails.forEach((item) => {
    if (item?._id !== currentUser?.user_id) {
      nameArray.push(item?.userInfo?.name);
      imageArray.push(item?.userInfo?.profilePhoto);
    }
  });
  let names = nameArray?.join(", ");
  //   const [lastReadIndex, setLastReadIndex] = useState(
//     participants.lastReadIndex
//   );
//   const handleConversationClick = (conversationId) => {
//     setLastReadIndex(totalMessages);
//     //  unreadMessage = totalMessages - lastReadIndex;

//     activeConversation(conversationId);
//   };

  let unreadMessage = totalMessages - participants.lastReadIndex;
  //   let unreadMessage = totalMessages - lastReadIndex;
  //   useEffect(() => {
//     // Update the lastReadIndex when the info prop changes
//     setLastReadIndex(participants.lastReadIndex);
//   }, [participants.lastReadIndex]);

  return (
    <span
      className="d-flex align-items-center msg-chatBox "
      key={key}
      onClick={() => activeConversation(_id)}
      // onClick={() => handleConversationClick(_id)}
      
    >
      <div className="flex-shrink-0">
        {imageArray &&
          imageArray?.length > 0 &&
          imageArray?.map((imgsrc, index) => (
            <img
              className={`${index == 0 ? "usr-img" : "user-small-img"}`}
              src={imgsrc ? imgsrc : Images?.defaultUserImage}
              alt="user img"
            />
          ))}
      </div>
      <div className="flex-grow-1 ms-3">
        <h3>{names}</h3>
        <p>{lastMessage && lastMessage?.message ? lastMessage?.message : ""}</p>
      </div>
      <div className="msg-time-count">
        <p className="msg-time">{getTimeDiffOutput(updatedAt)}</p>
        {unreadMessage !== 0 && <p className="msg-count">{unreadMessage}</p>}
      </div>
    </span>
  );
}

