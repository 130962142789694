import React, { useEffect, useState } from "react";
import { getSupport, updateSupport } from "../../../redux/slices/web";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { useWebSelector } from "../../../redux/selector/web";
import SpinnerGrow from "../../../common/SpinnerGrow";
import moment from "moment";
import { encodeData } from "../../../utilities/helper";
import { useNavigate } from "react-router-dom";

function Support() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const webselector = useWebSelector();
  const supportTickets = webselector?.supportData?.supportTickets;
  const Myloading = webselector?.loading;

  const getAllSupport = (page = currentPage) => {
    let params = {
      limit: 10,
      page: page,
    };
    // setLoading(true);
    dispatch(
      getSupport({
        ...params,
        cb(res) {
          setPageCount(res?.data?.data?.total_pages);
          // setLoading(false);
        },
      })
    );
  };
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };
  useEffect(() => {
    getAllSupport();
  }, [currentPage]);

  const userDetailPage = (userID) => {
    if (!userID || userID == "") return;
    navigate(`/manage-account/view-accounts/${encodeData(userID)}`);
  };

  const updateStatus = (id, status) => {
    if (!status || status == "") return;

    const params = {
      id: id,
      status: status
    }

    dispatch(updateSupport({
      ...params,
      cb(res) {
        if (res.status == 200) {
          getAllSupport()
        }
      }
    }))
  }

  const status = [
    { status: "open", label: "Open", value: "open" },
    { status: "inprogress", label: "In Progress", value: "inprogress" },
    { status: "closed", label: "Ticket Closed", value: "closed" }
  ]

  const statusNames = {
    open: "Open",
    inprogress: "In Progress",
    closed: "Ticket Closed"
  }

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Support</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="content commonBox_  userlistbox">
        <div className="container-fluid">
          <table className="usertable ">
            <thead>
              <tr className="tableborder">
                <th>Booking Name</th>
                <th>reported</th>
                <th>reported By</th>
                <th>reason</th>
                {/* <th>reasonDescription</th> */}
                <th>reported on </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Myloading ? (
                <tr>
                  <td colSpan={9} className="py-5">
                    <SpinnerGrow />
                  </td>
                </tr>
              ) : supportTickets && supportTickets.length > 0 ? (
                supportTickets.map((support, index) => {
                  const reportedUser = support?.conversationId.participants && support?.conversationId.participants.length &&
                    support?.conversationId.participants.find(user => user?.userId && user?.userId?._id != support?.reportedBy?._id && user?.userId?.role !== "admin") || {}
                  return (
                    <tr key={index}>
                      <td>{support?.jobId?.service?.categoryName ?? "---"}</td>
                      <td className="cursor-pointer" onClick={() => { userDetailPage(reportedUser?.userId?._id) }}>
                        {reportedUser ? reportedUser?.userId?.userInfo?.name || " ---" : "---"}
                      </td>
                      <td>{support?.reportedBy?.userInfo?.name ?? "---"}</td>
                      <td>{support?.reason === "Something Else"
                        ? support?.reasonDescription ?? "---"
                        : support?.reason ?? "---"}</td>
                      <td>
                        {moment(support?.createdAt).format("MMMM D, YYYY") ?? "---"}
                      </td>
                      <td className="cursor-pointer" >
                        <div class="btn-group">
                          <button class="btn btn-secondary btn-sm dropdown_text" type="button">
                            {support?.status ? statusNames[support?.status] ?? "---" : "---"}
                          </button>
                          <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split dropdown_toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={support?.status == "closed"}>
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu">
                            {status && status.map((status, index) => (
                              <span class="dropdown-item" onClick={() => { updateStatus(support?._id, status.value) }}  >
                                {status.label}
                              </span>)
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={9} className="py-5">
                    <p className="text-center">No Data Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {supportTickets && supportTickets.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Support;
