import React from "react";
import { toast } from "react-toastify";
import selectionType from "../../../../constants/selectionType.json";
import * as Images from "../../../../utilities/images";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ServiceModifiers(props) {
  const {
    modifierValues,
    modifierValueChange,
    setModifierValues,
    submitModifier,
    reset,
    close,
    modifierIndex,
  } = props;
  
  const tooltiptext={
    single:"Allows customers to select a single modifier option from this group",
    multi:"Allows customers to select one or more modifiers from this group",
    quantity:"Allows customers to adjust quantities for each modifier option in this group."
  }

  const validate = () => {
    if (modifierValues?.title.trim() === "") {
      toast.error("Please enter the name");
      return;
    } else if (modifierValues?.description.trim() === "") {
      toast.error("Please enter the description");
      return;
    } else {
      submitModifier();
    }
  };
  const handleSelectionType = (values) => {
    setModifierValues((prevValues) => ({
      ...prevValues,
      selectionType: values,
    }));
  };

  const renderTooltip = (props, title) => (
    <Tooltip id="button-tooltip" {...props}  className="custom-tooltip">
      {title}
    </Tooltip>
  );
  return (
    <>
      <div className="addSubServiceOuter noshadow ">
        <div className="row mb-4">
          <div className="mb-3 col-md-12">
            <label className="form-label customLables">Name</label>

            <input
              type="text"
              className="form-control customControl"
              placeholder="Enter Name"
              name="title"
              value={modifierValues?.title}
              onChange={modifierValueChange}
            />
          </div>
          <div className="mb-3 col-md-12">
            <label className="form-label customLables">Description</label>

            <textarea
              className="form-control customControl"
              rows={6}
              placeholder="Write here..."
              name="description"
              value={modifierValues?.description}
              onChange={modifierValueChange}
            ></textarea>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <input
              type="checkbox"
              id="requiredcheckbox"
              checked={modifierValues.isRequired}
              onChange={(e) =>
                setModifierValues((prevValues) => ({
                  ...prevValues,
                  isRequired: e.target.checked,
                }))
              }
            />
            <label htmlFor="requiredcheckbox ">Required</label>
          </div>

          <div className="mb-3 col-md-12">
            <label className="form-label customLables mt-2 mb-2">Selection Type</label>
            <div className="d-flex modifierSelectBtns">
              <button
                onClick={() => handleSelectionType(selectionType.single)}
                className={`selectiontype_btn ${
                  modifierValues.selectionType === selectionType.single
                    ? "typeBtnActive"
                    : ""
                }`}
              >
                <span className="span_text "> Single Select</span>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(event) =>
                    renderTooltip(
                      event,
                      tooltiptext.single
                    )
                  }
                >
                  <img src={Images.question} alt="?" />
                </OverlayTrigger>
              </button>

              <button
                onClick={() => handleSelectionType(selectionType.multi)}
                className={`selectiontype_btn ${
                  modifierValues.selectionType === selectionType.multi
                    ? "typeBtnActive"
                    : ""
                }`}
              >
                   <span className="span_text ">  Multi-Select</span>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(event) =>
                    renderTooltip(
                      event,
                      tooltiptext.multi
                    )
                  }
                >
                  <img src={Images.question} alt="?" />
                </OverlayTrigger>
              </button>
              <button
                onClick={() => handleSelectionType(selectionType.quantity)}
                className={`selectiontype_btn ${
                  modifierValues.selectionType === selectionType.quantity
                    ? "typeBtnActive"
                    : ""
                }`}
              >
                  <span className="span_text ">   Quantity Select</span>
              
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(event) =>
                    renderTooltip(
                      event,
                      tooltiptext.quantity
                    )
                  }
                >
                  <img src={Images.question} alt="?" />
                </OverlayTrigger>
              </button>
            </div>
          </div>
        </div>

        <button
          className="cancelBtn me-4"
          onClick={(e) => {
            e.preventDefault();
            reset();
            close();
          }}
        >
          CANCEL
       </button>

        <button className="addBtn" type="button" onClick={validate}>
          {modifierIndex !== null ? "Update" : "Add"}
        </button> 
      </div>
    </>
  );
}

export default ServiceModifiers;
