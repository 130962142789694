import React, { useEffect } from "react";
import * as Images from "../../../utilities/images.js";
import {  useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIndustryById } from "../../../redux/slices/web/index.js";
import SpinnerGrow from "../../../common/SpinnerGrow.jsx";
import { decodeData } from "../../../utilities/helper.js";

const ServiceDetailsView = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const industryById = useSelector((state) => state.web.industryById);
  const loading = useSelector((state) => state.web.loading);
  useEffect(() => {
    if (params && params.serviceSlug) {
      const apiData = {
        id: decodeData(params.serviceSlug),
      };
      dispatch(
        getIndustryById({
          ...apiData,
          cb(response) {
            if (response.status === 200) {
            }
          },
        })
      );
    }
  }, [params]);


  const AreaDiscountBlock = (props) => {
    const { data } = props;
    return (
      <div className="mt-3 w-100">
        <span className="addOtherCategory"> Lawn Area</span>
        {data &&
          data.map((ele, index) => (
            <AreaInnerList element={ele} key={`lawn_saved_${index}`} />
          ))}
      </div>
    );
  };

  const AreaInnerList = (props) => {
    const { element, key } = props;
    return (
      <div className="lawnArea" key={key}>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <span className="fw-bold">{element.value} sq. ft</span>
            <span>$ {element.discountedPrice}</span>
          </div>
          <span>{element.discountPercentage}% off</span>
        </div>
      </div>
    );
  };

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              
              <h1 className="m-0 headingMain">Service Details</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="subServiceDetails">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12">
              {loading ? (
                <div className="my-5">
                  {" "}
                  <SpinnerGrow />{" "}
                </div>
              ) : (
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <img
                      src={
                        industryById && industryById.image
                          ? industryById.image
                          : Images.serviceDetailsImg
                      }
                      className="serviceDetailsImg"
                      alt="image"
                    />
                  </div>
                  <div className="col-md-8">
                    <h2 className="heading32 mb-3">
                      {industryById && industryById.name
                        ? industryById.name
                        : ""}{" "}
                    </h2>
                    <p className="text16 mb-2">
                      {industryById && industryById.description
                        ? industryById.description
                        : ""}
                    </p>
                    <p className="priceService">
                      $
                      {industryById && industryById.price
                        ? industryById.price
                        : ""}
                    </p>
                  </div>
                </div>
              )}
              {/* Recurring Start*/}
              {industryById && industryById.isRecurring ? (
                <>
                  <div className="addSubService mt-4 mb-3">
                    <span className="addOtherCategory ">Recurring</span>
                  </div>
                  <div className="row">
                    {industryById &&
                    industryById.recurringOptions &&
                    industryById.recurringOptions.length > 0
                      ? industryById.recurringOptions.map(
                          (recurring, recurring_index) => (
                            <div
                              className="col-md-4"
                              key={`recurringOptions_${recurring_index}`}
                            >
                              <div className="recurringOuter">
                                <p className="weeklyServie">
                                  {recurring.title}
                                </p>
                                <p className="weeklyOff">
                                  {recurring.discountPercentage}% off
                                </p>
                              </div>
                            </div>
                          )
                        )
                      : ""}
                  </div>
                </>
              ) : (
                ""
              )}
              {/* Recurring End*/}

              <div className="addSubService">
                <span className="addOtherCategory mt-4">Sub Services</span>
              </div>
              <div className=" mt-4">
                <div className="row mb-4">
                  {loading ? (
                    <div className="my-5">
                      {" "}
                      <SpinnerGrow />{" "}
                    </div>
                  ) : (
                    industryById &&
                    industryById.types &&
                    industryById.types.length > 0 &&
                    industryById.types.map((service, service_index) => (
                      <div
                        className="col-md-6  mb-3"
                        key={`service_${service_index}`}
                      >
                        <div className="smallLawnOuter">
                          <div className="d-flex">
                            <div className="smallLawnImg">
                              <img src={service.image ?? Images.SmallLawn} alt="image" />
                            </div>
                            <div className="smallLawnData">
                              <h4 className="smallLawnHeading mb-1">
                                {service?.title}
                              </h4>
                              <p className="mb-2">{service?.description}</p>
                              <h6>${service?.price}</h6>
                            </div>
                          </div>
                          {service.area && service.area.length > 0 && (
                            <AreaDiscountBlock data={service.area} />
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="addSubService mt-4 ">
                <span className="addOtherCategory">Add On</span>
              </div>
              {loading ? (
                <div className="my-5">
                  {" "}
                  <SpinnerGrow />{" "}
                </div>
              ) : industryById &&
                industryById.addOns &&
                industryById.addOns.length > 0 ? (
                industryById.addOns.map((addOns, addOns_index) => (
                  <div key={`addOns_${addOns_index}`} className="my-3">
                    <h4 className="smallLawnHeading mb-1">
                      {" "}
                      {addOns.title ? addOns["title"] : ""}
                    </h4>
                    <p className="text16">
                      {addOns.title ? addOns["title"] : ""}
                    </p>
                    <div className="addSubServiceOuter mt-4">
                      <div className="row">
                        {addOns &&
                          addOns.types &&
                          addOns.types.length > 0 &&
                          addOns.types.map(
                            (addOns_types, addOns_types_index) => (
                              <div
                                className="col-md-6 mb-3"
                                key={`addOns_types_${addOns_types_index}`}
                              >
                                <div className="smallLawnOuter">
                                  <div className="smallLawnData">
                                    <h4 className="smallLawnHeading mb-1">
                                      {addOns_types.title
                                        ? addOns_types.title
                                        : ""}
                                    </h4>
                                    <p className="mb-2">
                                      {addOns_types.description
                                        ? addOns_types.description
                                        : ""}
                                    </p>
                                    <h6>
                                      $
                                      {addOns_types.price
                                        ? addOns_types.price
                                        : ""}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center my-2">
                  No Add Ons
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceDetailsView;
