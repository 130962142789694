import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllUser,
  updateActiveInactiveStatus,
  deleteUser,
} from "../../../redux/slices/web";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { encodeData, userRoleName, userRoles } from "../../../utilities/helper";

const ManageAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useSearchParams()
  const role = queryParams?.get('role');

  const [usersDataInfo, setUsersDataInfo] = useState([]);
  const [active, setActive] = useState("All");
  const [accountType, setAccountType] = useState(role ? role : userRoles.all);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const authData = useAuthSelector();

  useEffect(() => {
    getAllAccountsInformation();
  }, [active, accountType, search, currentPage]);

  const getAllAccountsInformation = (page = currentPage) => {
    let params = {
      limit: 10,
      page: page,
    };
    if (active !== "All") {
      params.status = active;
    }
    if (accountType !== "All") {
      params.role = userRoles[accountType];
    }
    if (search !== "") {
      params.search = search;
    }

    dispatch(
      getAllUser({
        ...params,
        cb(res) {
          setUsersDataInfo(res?.data?.data?.users);
          setPageCount(res?.data?.data?.total_pages);
        },
      })
    );
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  // update status
  const updateUserActiveInactiveStatus = (id, status) => {
    Swal.fire({
      title: `Do you want to ${status == "active" ? "activate " : "deactivate"
        } this account?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "green-btn",
      },
    }).then(({ isConfirmed }) => {
      let params = {
        id: id,
        status: status,
      };
      if (isConfirmed) {
        dispatch(
          updateActiveInactiveStatus({
            ...params,
            cb(res) {
              if (res.status === 200) {
                getAllAccountsInformation();
              }
            },
          })
        );
      }
    });
  };

  const handleDeleteUser = (val) => {
    Swal.fire({
      title: "Do you want to delete this account?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "green-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: val?._id,
        };
        dispatch(
          deleteUser({
            ...params,
            cb(res) {
              if (res.status === 200) {
                usersDataInfo.forEach((user, index) => {
                  if (user._id === params.id) {
                    if (user.role === userRoles.client) {
                      toast.success("Customer Deleted Successfully");
                    } else if (user.role === "provider") {
                      toast.success("Provider Deleted Successfully");
                    }
                  }
                });
                getAllAccountsInformation();
              } else {
                console.log("there is some error in the deleting the user");
              }
            },
          })
        );
      }
    });
  };

  const handleGoToDetailPage = (data) => {
    navigate(`/manage-account/view-accounts/${encodeData(data._id)}`);
  };

  const changeRole = (role) => {
    queryParams?.delete('role')
    setQueryParams(queryParams)
    setAccountType(role)
  }

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Manage Accounts </h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <section className="mb-2">
        <div className="container-fluid">
          <div className="row">
            <div className=" mt-4 userFilter">
              <div
                className="d-flex commonFilterHeader clickable"
                onClick={() =>
                  document
                    .getElementById("statusDropdown")
                    .classList.toggle("show")
                }
              >
                <p className="commonBox_Heading m-0">Status ({active})</p>
                <div className="dropdown filterDropdown userListFilter">
                  <button
                    className="filterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-filter"></i>
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end my_dropbox"
                    id="statusDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setActive("All")}
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setActive("active")}
                      >
                        Active
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setActive("inactive")}
                      >
                        InActive
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="d-flex commonFilterHeader clickable"
                onClick={() =>
                  document
                    .getElementById("RoleDropdown")
                    .classList.toggle("show")
                }
              >
                <p className="commonBox_Heading m-0">Role({accountType})</p>
                <div className="dropdown filterDropdown userListFilter">
                  <button
                    className="filterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-filter"></i>
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end my_dropbox"
                    id="RoleDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item font-14 subtext"
                        onClick={(e) => changeRole(userRoles.all)}
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item font-14 subtext"
                        onClick={(e) => changeRole(userRoles.provider)}
                      >
                        Provider
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item font-14 subtext"
                        onClick={(e) => changeRole(userRoles.customerKey)}
                      >
                        Customer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex commonFilterHeader  userSearch ">
                <input
                  type="search"
                  className="filterSearch"
                  placeholder="search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content commonBox_  userlistbox">
        <div className="container-fluid">
          <table className="usertable">
            <tr className="tableborder">
              <th>Name</th>
              <th>Email</th>
              <th>Zip Code</th>
              <th>Account Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {authData.loading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center ">
                      <div className="spinner-grow " role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {usersDataInfo && usersDataInfo.length > 0 && (
                  <>
                    {usersDataInfo?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.userInfo?.name ?? "---"}</td>

                        <td className="text-lowercase">{data?.email}</td>

                        <td>
                          {data?.providerInfo.zipCode
                            ? data?.providerInfo.zipCode
                            : "--"}
                        </td>
                        <td>{userRoleName[data?.role]}</td>
                        <td className={` activeBtn ${data.status == "active" ? "" : "inactivetxt"}`}>{data?.status}</td>
                        <td>
                          <div className="dropdown filterDropdown">
                            {data.status == "active" ? (
                              <button
                                className=" activeBtn_ "
                                onClick={() => {
                                  updateUserActiveInactiveStatus(
                                    data?._id,
                                    "inactive"
                                  );
                                }}
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                className="inActiveBtn"
                                onClick={() => {
                                  updateUserActiveInactiveStatus(
                                    data?._id,
                                    "active"
                                  );
                                }}
                              >
                                Inactive
                              </button>
                            )}
                            <button
                              className="activeBtn_ viewbtn"
                              onClick={(e) => handleGoToDetailPage(data)}
                            >
                              View
                            </button>
                            <button
                              className="activeBtn_ viewbtn"
                              onClick={(e) => handleDeleteUser(data)}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </table>

          {(!usersDataInfo || usersDataInfo.length === 0) && (
            <div className="d-flex justify-content-center my-4">
              No Data Found
            </div>
          )}

          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {usersDataInfo?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "addAccount" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "addAccount" ? "tablemodalbtn" : ""}
        child={
          modalDetail.flag === "addAccount" ? (
            <AddAccount close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editAccount" ? (
            <EditAccount close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "addAccount" ? (
            <>
              <h2 className="modal_Heading">Add Account</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : modalDetail.flag === "editAccount" ? (
            <>
              <h2 className="modal_Heading">Edit Account</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      /> */}
    </div>
  );
};

export default ManageAccounts;
