import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../redux/slices/auth";


const ProtectRoute = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem("adminAuthToken"));
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem("adminAuthToken");
    if (token) {
      const decoded = jwtDecode(token);
      dispatch(setLoggedInUser(decoded))
    }
  }, [])
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectRoute;
