import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setUserChefList,
  setUpdateStatus,
  onErrorStopLoad,
  setVerifyDocument,
  setGetHelperPages,
  setUpdateHelperPages,
  setGetSingleHelperPage,
  setUpdateHelperPageStatus,
  setDeleteHelperPage,
  setgetStats,
  setGetAllUser,
  setUpdateActiveInactiveStatus,
  setAllIndustry,
  setPrivacyPolicy,
  setPrivacyPolicyUpdate,
  setIndustryDetailsById,
  setGetTermsList,
  setOneAcountDetail,
  setDeleteUser,
  setProviderService,
  setBooking,
  setUserRoleProvider,
  setOneSubservices,
  setSupport,
  supportSaved,
} from "../../slices/web";

function* getAllUser(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ALL_USERS}`),
      (action.params = { params: dataToSend })
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setGetAllUser(resp?.data?.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));

    toast.error(e?.response?.data?.message);
  }
}

function* updateActiveInactiveStatus(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_ACTIVE_INACTIVE_STATUS}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setUpdateActiveInactiveStatus(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* getAllIndustry(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ALL_INDUSTRY}`),
      (action.params = { params: dataToSend })
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setAllIndustry(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));

    // toast.error(e?.response?.data?.message);
  }
}

function* getIndustryById(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_INDUSTRY_BY_ID}/${dataToSend.id}`),
      (action.params = { params: dataToSend })
      // (params =  ) 
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setIndustryDetailsById(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));

    toast.error(e.response?.data?.message);
  }
}

function* addIndustry(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.ADD_INDUSTRY}`),
      dataToSend
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setAllIndustry(action?.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    // yield call(action?.payload?.cb, (action.res = {}));

    toast.error(
      e?.response?.data && e?.response?.data?.length != 0
        ? e.response.data[0]
        : "Some Error Occured"
    );
  }
}
function* editIndustry(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;
  delete dataToSend._id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.ADD_INDUSTRY}/${action?.payload?._id}`),
      dataToSend
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // yield put(setAllIndustry(action?.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    // yield call(action?.payload?.cb, (action.res = {}));

    toast.error(
      e?.response?.data && e?.response?.data?.length != 0
        ? e.response.data[0]
        : "Some Error Occured"
    );
  }
}

function* deleteIndustry(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.webApiPath.DELETE_INDUSTRY}/${action?.payload?.id}`),
      dataToSend
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setAllIndustry(action?.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    // yield call(action?.payload?.cb, (action.res = {}));

    toast.error(
      e?.response?.data && e?.response?.data?.length != 0
        ? e.response.data[0]
        : "Some Error Occured"
    );
  }
}

function* uploadFile(action) {
  let dataToSend = { ...action.payload };

  delete dataToSend.name;

  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = `${ApiPath.webApiPath.ADD_FILE}`),
      dataToSend
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setAllIndustry(action?.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());

    // toast.error(e.response.data.message);
  }
}

function* getDashboardData(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.DASHBOARD}`),
      (action.params = { params: dataToSend })
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setAllIndustry(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));
    toast.error(e?.response?.data?.message);
  }
}

function* privacyPolicy(action) {
  try {
    const resp = yield call(
      ApiClient.get,

      (action.url = `${ApiPath.contentManagement.PRIVACY_POLICY}${action.payload.slug}?=${action.payload.slug}`),
      action.payload
    );

    if (resp.status) {
      yield put(setPrivacyPolicy(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPrivacyPolicy({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}
function* getTermsList(action) {
  try {
    const resp = yield call(
      ApiClient.get,

      (action.url = `${ApiPath.contentManagement.PRIVACY_POLICY}${action.payload.slug}?=${action.payload.slug}`),
      action.payload
    );

    if (resp.status) {
      yield put(setGetTermsList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetTermsList({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* privacyPolicyUpdate(action) {
  const data = { ...action.payload };
  delete data.id;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.contentManagement.PRIVACY_UPDATE}${action.payload.id}`),
      // action.payload
      data
    );
    if (resp.status) {
      toast.success(resp.data.message);
      yield put(setPrivacyPolicyUpdate(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPrivacyPolicyUpdate({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* getOneAccountDetail(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ONE_USERS}?id=${action.payload.id}`)
    );
    if (resp.status) {
      // toast.success(resp.data.message);
      yield put(setOneAcountDetail(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setOneAcountDetail({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}
function* deleteUser(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.webApiPath.GET_ALL_USERS}/${action.payload.id}`)
    );
    if (resp.status) {
      // toast.success(resp.data.message);
      yield put(setDeleteUser(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* getproviderService(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_PROVIDERS_SERVICES}?userId=${action.payload.userId}`)
    );
    if (resp.status) {
      // toast.success(resp.data.message);
      yield put(setProviderService(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield put(setProviderService({}));
    toast.error(e?.response?.data?.msg);
  }
}
function* getBooking(action) {
  try {
    let dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ALL_JOBS}`),
      (action.params = { params: dataToSend })
    );
    if (resp.status) {
      // toast.success(resp.data.message);
      yield put(setBooking(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield put(setBooking({}));
    toast.error(e?.response?.data?.msg);
  }
}
function* getUserRoleProvider(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ALL_USERS}`),
      (action.params = { params: dataToSend })
      // (params =  )
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setUserRoleProvider(resp.data.data.users));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));

    toast.error(e?.response?.data?.message);
  }
}
function* getSubServicesUpdate(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;
  delete dataToSend.serviceId;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.SUBSERVICES_UPDATE}/${action?.payload?.serviceId}`),
      dataToSend
    );
    if (resp.status) {
      toast.success(resp?.data?.message)
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    // yield call(action?.payload?.cb, (action.res = {}));
    toast.error(e?.response?.data?.message)
    // toast.error(
    //   e?.response?.data && e?.response?.data?.length != 0
    //     ? e.response.data[0]
    //     : "Some Error Occured"
    // );
  }
}
function* getOneSubServices(action) {
  let dataToSend = { ...action.payload };
  // delete dataToSend.cb;
  // delete dataToSend.serviceId;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.SUBSERVICES_UPDATE}/${action?.payload?.serviceId}`),
      dataToSend
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setOneSubservices(resp?.data?.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield put(setOneSubservices({}))
    // yield call(action?.payload?.cb, (action.res = {}));

    // toast.error(
    //   e?.response?.data && e?.response?.data?.length != 0
    //     ? e.response.data[0]
    //     : "Some Error Occured"
    // );
  }
}

function* getSupport(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.SUPPORT}`),
      (action.params = { params: dataToSend })
    );
    if (resp.status) {
      yield put(setSupport(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield put(setSupport({}));
    toast.error(e?.response?.data?.msg);
  }
}
function* updateSupportStatus(action) {
  let dataToSend = { ...action.payload };
  delete dataToSend.cb;
  delete dataToSend.id;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.SUPPORT}${action.payload.id}`),
      dataToSend
    );
    if (resp.status) {
      yield put(supportSaved(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield put(setSupport({}));
    toast.error(e?.response?.data?.msg);
  }
}




function* webSaga() {
  yield all([takeLatest("web/getAllUser", getAllUser)]);
  yield all([takeLatest("web/updateActiveInactiveStatus", updateActiveInactiveStatus),]);
  yield all([takeLatest("web/getAllIndustry", getAllIndustry)]);
  yield all([takeLatest("web/addIndustry", addIndustry)]);
  yield all([takeLatest("web/uploadFile", uploadFile)]);
  yield all([takeLatest("web/editIndustry", editIndustry)]);
  yield all([takeLatest("web/deleteIndustry", deleteIndustry)]);
  yield all([takeLatest("web/getDashboardData", getDashboardData)]);
  yield all([takeLatest("web/privacyPolicy", privacyPolicy)]);
  yield all([takeLatest("web/getTermsList", getTermsList)]);
  yield all([takeLatest("web/privacyPolicyUpdate", privacyPolicyUpdate)]);
  yield all([takeLatest("web/getIndustryById", getIndustryById)]);
  yield all([takeLatest("web/getOneAccountDetail", getOneAccountDetail)]);
  yield all([takeLatest("web/deleteUser", deleteUser)]);
  yield all([takeLatest("web/getproviderService", getproviderService)]);
  yield all([takeLatest("web/getBooking", getBooking)]);
  yield all([takeLatest("web/getUserRoleProvider", getUserRoleProvider)]);
  yield all([takeLatest("web/getOneSubServices", getOneSubServices)]);
  yield all([takeLatest("web/getSubServicesUpdate", getSubServicesUpdate)]);
  yield all([takeLatest("web/getSupport", getSupport)]);
  yield all([takeLatest("web/updateSupport", updateSupportStatus)]);

}

export default webSaga;
