import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images.js";
import { useServiceSelector } from "../../../../redux/selector/service.js";
import {
  createCategories,
  createServices,
  getAllCategories,
} from "../../../../redux/slices/services/index.js";
import { uploadFile } from "../../../../redux/slices/web/index.js";

export default function AddService(props) {
  const { handleClose, getServiceByCategory } = props;
  const dispatch = useDispatch();
  const [serviceImage, setServiceImage] = useState(null);
  const serviceSelector = useServiceSelector();
  const categoriesList = serviceSelector?.categoriesList || [];
  const [formValues, setFormValues] = useState({
    name: "",
    categoryId: props?.isDrop ? props?.categoryId : "",
    description: "",
  });

  const handleFormValuesChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleServiceImage = (event) => {
    let params = {
      file: event.target.files[0],
    };
    dispatch(
      uploadFile({
        ...params,
        cb(res) {
          setServiceImage(res?.data?.data?.fileUrl);
        },
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    const finalValues = { ...formValues };

    if (!formValues.categoryId) {
      isValid = false;
      toast.error("Please select category");
    } else if (!formValues.name || formValues.name.trim() == "") {
      isValid = false;
      toast.error("Please enter name");
    } else if (serviceImage == null) {
      isValid = false;
      toast.error("Service Image is Required");
    }

    if (serviceImage) {
      finalValues.image = serviceImage;
    }
    if (!isValid) return;

    dispatch(
      createServices({
        ...finalValues,
        cb(res) {
          if (res.status == 200) {
            props.handleClose && props.handleClose();
            props.getServiceByCategory && props.getServiceByCategory();
          }
        },
      })
    );
  };

  const getCategoryList = () => {
    let params = {};
    dispatch(
      getAllCategories({
        ...params,
        cb(res) {},
      })
    );
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <span>
          A service is something your customers can book online. For example, a
          home cleaning or a junk removal pickup.
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 my-3 ps-0">
            <label className="form-label customLables">Category</label>
            <div className="position-relative">
              {/* {props.addService === true && (
                <img src={Images.UpDownArrow} className="upDownArrow" />
              )} */}

              <select
                className="form-select customSelect"
                aria-label="Default select example"
                name="categoryId"
                onChange={handleFormValuesChange}
                value={props.isDrop ? props.categoryId : formValues.categoryId}
              >
                <option value="">Select Category</option>
                {categoriesList && categoriesList.length > 0
                  ? categoriesList.map((category, index) => (
                      <option key={`category_${index}`} value={category._id}>
                        {category.name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-6 my-3 ps-0">
            <label className="form-label customLables">Name</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              placeholder="Enter Service Name"
              onChange={handleFormValuesChange}
              className="form-control customControl"
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label customLables mb-2">Description</label>
            <textarea
              rows={8}
              name="description"
              value={formValues.description}
              placeholder="Write here..."
              onChange={handleFormValuesChange}
              className="form-control customControl serviceTxtArea"
            ></textarea>
          </div>
          <div className="col-md-6 mb-3">
            <div className="file-input mb-3">
              <span className="d-block customLables mb-2">Service Image</span>
              {serviceImage ? (
                <>
                   <div className="uploadImgOuter">
            <div className="previewOuter">
                  <img
                    src={serviceImage}
                    className="imagePreview serviceImagePreview"
                  />
                  <span
                    className="crossIcon____"
                    onClick={() => {
                      setServiceImage(null);
                    }}
                  >
                    <img src={Images.PlusIcon_} className="plusIcon______" />
                  </span>
                  </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <input
                    type="file"
                    name="file-input"
                    id="file-input"
                    className="file-input__input"
                    onChange={handleServiceImage}
                  />
                  <label
                    className="file-input__label servicelabel"
                    htmlFor="file-input"
                  >
                    <p className="dragText">
                      {" "}
                      <img src={Images.UploadImg} /> <br />
                      Drag image here or{" "}
                      <span className="browserTxt">Browse</span>
                      <br />
                      <span className="uploadCoverText">
                        Upload cover image
                      </span>
                    </p>
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="cancelBtn me-4"
            type="button"
            onClick={(e) => {
              handleClose();
            }}
          >
            CANCEL
          </button>
          <button className="addBtn" style={{ maxWidth: "187px" }}>
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
