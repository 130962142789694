import React from "react";
import * as Images from "../../../utilities/images";

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <img
    src={Images.threedots}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation()
      onClick(e);
    }}
    style={{ height: "22px", cursor: "pointer" }} 
    alt="Three dots"
  />
));

export default CustomToggle;
