import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  serviceObj: {},
  serviceList: [],
  categoriesObj: {},
  categoriesList: [],
  addOnDatamodal: {},
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    getAllServices(state, action) {
      state.loading = true;
    },
    setAllServices(state, action) {
      state.loading = false;
      state.serviceObj = action?.payload?.data || {};
      state.serviceList = action?.payload?.data?.services || [];
    },
    getAllCategories(state, action) {
      state.loading = true;
    },
    setAllCategories(state, action) {
      state.loading = false;
      state.categoriesObj = action?.payload?.data || {};
      state.categoriesList = action?.payload?.data?.categories || [];
    },
    createCategories(state, action) {
      state.loading = true;
    },
    createServices(state, action) {
      state.loading = true;
    },
    updateCategories(state, action) {
      state.loading = true;
    },
    setUpdateCategories(state, action) {
      state.loading = false;
    },
    updateServices(state, action) {
      state.loading = true;
    },
    getServiceById(state, action) {
      state.loading = true;
    },
    setServiceById(state, action) {
      state.loading = false;
    },
    setAddOnData(state, action) {
      state.addOnDatamodal = action.payload;
    },
    deleteService(state, action) {},
    deleteCategory(state,action){},
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getAllServices,
  setAllServices,
  createServices,
  updateServices,
  deleteService,
  getServiceById,
  setServiceById,
  createCategories,
  getAllCategories,
  updateCategories,
  setUpdateCategories,
  setAllCategories,
  onErrorStopLoad,
  setAddOnData,
  deleteCategory
} = serviceSlice.actions;

export default serviceSlice.reducer;
