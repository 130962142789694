import React from "react";
import { useNavigate } from "react-router-dom";
import { encodeData } from "../../../../../utilities/helper";
export default function ServiceDetails(props) {
  const { serviceDetail, bookingDetail } = props;
  const navigate = useNavigate();

  const handleRedirection = () => {
    navigate(`/view-service/${encodeData(serviceDetail?._id)}`, {
      state: { edit: false, industryId: serviceDetail?._id },
    });
  };
  const dateFormatter = (date) => {
    const dateObj = new Date(date);
    return dateObj?.toDateString();
  };

  if (!serviceDetail || !bookingDetail) return null;

  return (
    <div className="msg-body-head">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 pe-4">
            <div className="oragnizationdetails d-flex align-items-center">
              <img
                className="me-3"
                src={serviceDetail.image}
                alt=""
                height={"50px"}
                width={"50px"}
              />
              <div className="organDetails">
                <h3 className="organNameTxt">{serviceDetail?.name}</h3>
                <p className="greenPrice">${serviceDetail?.price}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="d-flex align-items-center">
              <div>
                <p className="location-date mb-2">
                  Location:{" "}
                  <span className="locationDetails">
                    {`
                                    ${
                                      bookingDetail?.address?.houseNo &&
                                      bookingDetail?.address?.houseNo + ", "
                                    }
                                    ${
                                      bookingDetail?.address?.street &&
                                      bookingDetail?.address?.street + ", "
                                    }
                                    ${
                                      bookingDetail?.address?.city &&
                                      bookingDetail?.address?.city + ", "
                                    }
                                    ${
                                      bookingDetail?.address?.state &&
                                      bookingDetail?.address?.state + ", "
                                    }
                                    `}
                  </span>{" "}
                </p>
                <p className="location-date">
                  Date & Time:{" "}
                  <span className="locationDetails">
                    {dateFormatter(bookingDetail?.date)}
                  </span>{" "}
                  &nbsp;{" "}
                  <span className="locationDetails">
                    {bookingDetail?.time?.from} to {bookingDetail?.time?.to}
                  </span>{" "}
                </p>
              </div>
              <a
                className="right-angel-btn ms-auto"
                onClick={handleRedirection}
              >
                <i class="fa-solid fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
