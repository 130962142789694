import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: false,
    isFetching: false,
    isUpdating: false,
    shareData: {},
    transactions: [],
    transactionById: {},
}

export const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        getAllTransactions: (state, action) => {
            state.loading = true
        },
        setAllTransactions: (state, action) => {
            state.loading = false
            state.transactions = action.payload || []
        },
        getTransactionShare: (state, action) => {
            state.loading = true
        },
        setTransactionShare: (state, action) => {
            state.loading = false
            state.shareData = action.payload || {}
        },
        updateTransactionShare: (state, action) => {
            state.isUpdating = true
        },
        shareUpdated: (state, action) => {
            state.isUpdating = false
        },
        getTransactionById: (state, action) => {
            state.loading = true
        },
        setTransactionById: (state, action) => {
            state.loading = false
            state.transactionById = action.payload || {}
        }
    }
})

export const {
    getAllTransactions,
    setAllTransactions,
    getTransactionShare,
    setTransactionShare,
    getTransactionById,
    setTransactionById,
    updateTransactionShare,
    shareUpdated,
} = transactionSlice.actions

export default transactionSlice.reducer