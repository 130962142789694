import { all, fork, spawn } from "redux-saga/effects";
import authSaga from "./auth";
import webSaga from "./web";
import txnSaga from "./transaction";
import chatSaga from "./chat";
import serviceSaga from "./services";

export default function* rootSaga() {
  yield all([
    spawn(authSaga),
    spawn(webSaga),
    spawn(txnSaga),
    spawn(serviceSaga),
    spawn(chatSaga),
  ]);
}
