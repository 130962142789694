import { CKEditor } from "ckeditor4-react";
import { useState, useEffect, Children } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useWebSelector } from "../../../redux/selector/web";
import { onErrorStopLoad } from "../../../redux/slices/auth";
import { privacyPolicy, privacyPolicyUpdate } from "../../../redux/slices/web";

const ckeditorConfig = {
  versionCheck: false,
  height: "500px",
  extraPlugins: "font", // Add the font plugin which includes FontSize
  fontSize_sizes:
    "8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;36/36px;48/48px;72/72px;", // Define font size options
  removePlugins: [
    "EasyImage",
    "ImageUpload",
    "MediaEmbed",
    "Table",
    "TableToolbar",
    "image",
  ],
  toolbarLocation: ["top"],
  removeButtons: "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
};
const Contact = () => {
  const { loading } = useWebSelector();
  const { buttonLoading } = useWebSelector();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [slug, setSlug] = useState("privacy_policy");
  const [ckKey, setCkKey] = useState(Math.random());
  const [id, setId] = useState();
  const [terms, setTerms] = useState();
  const [privacy, setPrivacy] = useState(undefined);
  const [about, setAbout] = useState();
  const [contact, setContact] = useState();

  const getPrivacyList = () => {
    const params = {
      slug: slug,
    };

    dispatch(
      privacyPolicy({
        ...params,
        cb(res) {
          if (res?.data) {
            if (slug === "privacy_policy") {
              setPrivacy(res?.data?.data?.content);
              setTerms(undefined);
              setAbout(undefined);
              setContact(undefined);
              setData(undefined);
              setId(res?.data?.data?._id);
            } else if (slug === "terms_and_conditions") {
              setTerms(res?.data?.data?.content);
              setPrivacy(undefined);
              setAbout(undefined);
              setData(undefined);
              setContact(undefined);
              setId(res?.data?.data?._id);
            } else if (slug === "about_us") {
              setAbout(res?.data?.data?.content);
              setPrivacy(undefined);
              setData(undefined);
              setTerms(undefined);
              setContact(undefined);
              setId(res?.data?.data?._id);
            } else if (slug === "contact_us") {
              setContact(res?.data?.data?.content);
              setPrivacy(undefined);
              setTerms(undefined);
              setData(undefined);
              setAbout(undefined);
              setId(res?.data?.data?._id);
            }
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleUpdate = () => {
    const params = {
      id: id,
      content: data,
      active: true,
    };

    dispatch(
      privacyPolicyUpdate({
        ...params,
        cb(res) {
          if (res?.data) {
            setData(res.data?.data?.content);
            getPrivacyList();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  const handleEditorChange = (event, editor) => {
    const data = event.editor.getData();
    setData(data);
  };

  useEffect(() => {
    getPrivacyList();
  }, [slug]);

  useEffect(() => {
    getPrivacyList();
  }, []);

  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="content-wrapper commonBox_ adminUsers">
      <div className="mb-2">
      <button
        className={`${slug === "privacy_policy"
          ? "activeTab mb-3 mt-3 me-3"
          : "inActiveTab me-3"
          }`}
        onClick={() => setSlug("privacy_policy")}
      >
        Privacy Policy
        {slug === "privacy_policy" && buttonLoading && (
          <span className="spinner-border spinner-border-sm ms-1"></span>
        )}
      </button>

      <button
        className={`${slug === "terms_and_conditions"
          ? "activeTab mb-3 mt-3 "
          : "inActiveTab "
          }`}
        onClick={() => setSlug("terms_and_conditions")}
      >
        Terms & Condition
        {slug === "terms_and_conditions" && buttonLoading && (
          <span className="spinner-border spinner-border-sm ms-1"></span>
        )}
      </button>

      <button
        className={`${slug === "about_us" ? "activeTab mb-3 mt-3 ms-3" : "inActiveTab ms-3"
          }`}
        onClick={() => setSlug("about_us")}
      >
        About Us
        {slug === "about_us" && buttonLoading && (
          <span className="spinner-border spinner-border-sm ms-1"></span>
        )}
      </button>
      <button
        className={`${slug === "contact_us" ? "activeTab mb-3 mt-3 ms-3" : "inActiveTab ms-3"
          }`}
        onClick={() => setSlug("contact_us")}
      >
        Contact Us
        {slug === "contact_us" && buttonLoading && (
          <span className="spinner-border spinner-border-sm ms-1"></span>
        )}
      </button>
      </div>
      <div className={`${slug !== "privacy_policy" ? "d-none" : ""}`}>
        {privacy !== undefined && slug === "privacy_policy" && (
          <CKEditor
            key={ckKey}
            initData={privacy}
            config={ckeditorConfig}
            name="editor"
            readOnly={false}
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>
      <div className={`${slug !== "terms_and_conditions" ? "d-none" : ""}`}>
        {terms !== undefined && slug === "terms_and_conditions" && (
          <CKEditor
            key={ckKey}
            initData={terms}
            config={ckeditorConfig}
            name="editor"
            readOnly={false}
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>
      <div
        className={`${slug !== "about_us" ? "d-none" : ""}`}
      >
        {about !== undefined && slug === "about_us" && (
          <CKEditor
            key={ckKey}
            initData={about}
            config={ckeditorConfig}
            name="editor"
            readOnly={false}
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>
      <div
        className={`${slug !== "contact_us" ? "d-none" : ""}`}
      >
        {contact !== undefined && slug === "contact_us" && (
          <CKEditor
            key={ckKey}
            initData={contact}
            config={ckeditorConfig}
            name="editor"
            readOnly={false}
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>

      {data && (
        <button className="updateBtn_ mt-3" onClick={handleUpdate}>
          Update
          {buttonLoading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      )}
    </div>
  );
};
export default Contact;
