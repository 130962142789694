import React, { useState, useEffect ,useRef} from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../../redux/slices/web";
import * as Images from "../../../../utilities/images.js";
// import Cropper, { ReactCropperElement } from "react-cropper";
import {
  createCategories,
  updateCategories,
} from "../../../../redux/slices/services/index.js";
import { toast } from "react-toastify";

export default function AddCategory(props) {
  const {
    handleClose,
    getServiceByCategory,
    serviceName,
    serviceImage,
    serviceId,
    isEdit,
  } = props;
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
  });

  const handleServiceImage = (event) => {
    let params = {
      file: event.target.files[0],
    };
    var response = dispatch(
      uploadFile({
        ...params,
        cb(res) {
          setImage(res?.data?.data?.fileUrl);
        },
      })
    );
  };
  useEffect(() => {
    if (isEdit) {
      setFormValues({
        name: serviceName || "",
      });
      setImage(serviceImage || null);
    }
  }, []);

  const handleFormValuesChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    const finalValues = { ...formValues };
    if (image) finalValues.image = image;

    if (!formValues.name || formValues.name.trim() == "") {
      isValid = false;
      toast.error("Please enter name");
    } else if (!image) {
      isValid = false;
      toast.error("Please upload an image");
    }

    if (!isValid) return;
    if (isEdit) {
      dispatch(
        updateCategories({
          ...finalValues,
          id: serviceId,
          cb(res) {
            if(res.status ==200){
              toast.success(`Category updated successfully`)
              props.handleClose && props.handleClose();
              props.getServiceByCategory && props.getServiceByCategory();
            }
          },
        })
      );
    } else {
      dispatch(
        createCategories({
          ...finalValues,
          cb(res) {
            if(res.status == 200){
              toast.success(`Category created successfully`)
              props.handleClose && props.handleClose();
              props.getServiceByCategory && props.getServiceByCategory();
            }
          },
        })
      );
    }
  };


  return (
    <div className="row">
      <div className="col-md-12">
        <span>
          Use categories to organize your services into groups of related
          services.
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="col-md-12 mb-3 ps-0">
          <label className="form-label customLables">Name</label>
          <input
            type="text"
            className="form-control customControl"
            placeholder="Enter Category Name"
            name="name"
            onChange={handleFormValuesChange}
            value={formValues.name}
          />
        </div>
        <div className="file-input mb-3">
          <span className="d-block customLables mb-2">Image</span>
          
          {image ? (
            <>
            <div className="uploadImgOuter">
            <div className="previewOuter">
              <img src={image} className="imagePreview" />
              <span
                className="crossIcon____"
                onClick={() => {
                  setImage(null);
                }}
              >
                <img src={Images.PlusIcon_} className="plusIcon______" />
              </span>
              </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <input
                type="file"
                name="file-input"
                id="file-input"
                className="file-input__input"
                onChange={handleServiceImage}
              />
              <label className="file-input__label" htmlFor="file-input">
                <p className="dragText">
                  {" "}
                  <img src={Images.UploadImg} /> <br />
                  Drag image here or <span className="browserTxt">Browse</span>
                  <br />
                  <span className="uploadCoverText">Upload cover image</span>
                </p>
              </label>
            </>
          )}
         
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="cancelBtn me-4"
            type="button"
            onClick={(e) => {
              handleClose();
            }}
          >
            CANCEL
          </button>
          <button className="addBtn" style={{ maxWidth: "187px" }}>
            {isEdit ? "Edit" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}

// import React, { useState, useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
// import { uploadFile } from "../../../../redux/slices/web";
// import * as Images from "../../../../utilities/images.js";
// import Cropper, { ReactCropperElement } from "react-cropper";
// import {
//   createCategories,
//   updateCategories,
// } from "../../../../redux/slices/services/index.js";
// import { toast } from "react-toastify";
// import "cropperjs/dist/cropper.css";

// export default function AddCategory(props) {
//   const {
//     handleClose,
//     getServiceByCategory,
//     serviceName,
//     serviceImage,
//     serviceId,
//     isEdit,
//   } = props;
//   const dispatch = useDispatch();

//   const [image, setImage] = useState(null);
//   const [formValues, setFormValues] = useState({
//     name: "",
//   });

//   const handleServiceImage = (event) => {
//     let params = {
//       file: event.target.files[0],
//     };
//     var response = dispatch(
//       uploadFile({
//         ...params,
//         cb(res) {
//           setImage(res?.data?.data?.fileUrl);
//         },
//       })
//     );
//   };

//   useEffect(() => {
//     if (isEdit) {
//       setFormValues({
//         name: serviceName || "",
//       });
//       setImage(serviceImage || null);
//     }
//   }, [isEdit, serviceName, serviceImage]);

//   const handleFormValuesChange = (event) => {
//     const {
//       target: { name, value },
//     } = event;
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     let isValid = true;
//     const finalValues = { ...formValues };
//     if (image) finalValues.image = image;

//     if (!formValues.name || formValues.name.trim() === "") {
//       isValid = false;
//       toast.error("Please enter name");
//     } else if (!image) {
//       isValid = false;
//       toast.error("Please upload an image");
//     }

//     if (!isValid) return;
//     if (isEdit) {
//       dispatch(
//         updateCategories({
//           ...finalValues,
//           id: serviceId,
//           cb(res) {
//             props.handleClose && props.handleClose();
//             props.getServiceByCategory && props.getServiceByCategory();
//           },
//         })
//       );
//     } else {
//       dispatch(
//         createCategories({
//           ...finalValues,
//           cb(res) {
//             props.handleClose && props.handleClose();
//             props.getServiceByCategory && props.getServiceByCategory();
//           },
//         })
//       );
//     }
//   };

//   // image cropper functionality
//   const cropperRef = useRef(null);
//   const [imagec, setImagec] = useState(null);
//   const [cropData, setCropData] = useState("#");

//   const onChange = (e) => {
//     e.preventDefault();
//     let files;
//     if (e?.dataTransfer) {
//       files = e?.dataTransfer?.files;
//     } else if (e.target) {
//       files = e.target.files;
//     }
//     const reader = new FileReader();
//     reader.onload = () => {
//       setImagec(reader.result);
//     };
//     reader?.readAsDataURL(files[0]);
//   };

//   const getCropData = () => {
//     if (typeof cropperRef.current?.cropper !== "undefined") {
//       setCropData(
//         cropperRef?.current?.cropper?.getCroppedCanvas()?.toDataURL()
//       );
//     }
//   };

//   return (
//     <div className="row">
//       <div className="col-md-12">
//         <span>
//           Use categories to organize your services into groups of related
//           services.
//         </span>
//       </div>
//       <form onSubmit={handleSubmit}>
//         <div className="col-md-12 mb-3 ps-0">
//           <label className="form-label customLables">Name</label>
//           <input
//             type="text"
//             className="form-control customControl"
//             placeholder="Enter Category Name"
//             name="name"
//             onChange={handleFormValuesChange}
//             value={formValues.name}
//           />
//         </div>
//         <div className="file-input mb-3">
//           <span className="d-block customLables mb-2">Image</span>
//           <div style={{ width: "100%" }}>
//             {/* <input type="file" onChange={onChange} /> */}
//             <input
//                 type="file"
//                 name="file-input"
//                 id="file-input"
//                 className="file-input__input"
//                 onChange={onChange} 
//               />
            
//             <br />
//             <br />
//             <Cropper
//               style={{ height: 200, width: "100%" }}
//               initialAspectRatio={1}
//               preview=".img-preview"
//               src={imagec}
//               ref={cropperRef}
//               viewMode={1}
//               guides={true} 
//               minCropBoxHeight={10}
//               minCropBoxWidth={10}
//               background={false}
//               responsive={true}
//               checkOrientation={false} 
//             />
//           </div>
//           <div>
//             <div
//               className="box"
//             >
//               <div className="crop_image_btn">
//                 <button onClick={getCropData}>Crop Image</button>
//               </div>

//               <img style={{ width: "50%" }} src={cropData} alt="cropped" />
//             </div>
//           </div>
//           <br style={{ clear: "both" }} />
//         </div>
//         <div className="mt-4">
//           <button
//             className="cancelBtn me-4"
//             type="button"
//             onClick={(e) => {
//               handleClose();
//             }}
//           >
//             CANCEL
//           </button>
//           <button className="addBtn" style={{ maxWidth: "187px" }}>
//             {isEdit ? "Edit" : "Create"}
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }
