import React from "react";
import userimg from "../../../../../public/images/userimg.svg";
import { getTimeDiffOutput } from "../../../../../utilities/helper";

function isWithinOneMinute(date1, date2) {
  return Math.abs(new Date(date1) - new Date(date2)) < 60000;
}

export default function RightBlock(props) {
  const { messageObj, messages, index } = props;
  const isFirstMessage = index === 0;
  const isLastMessage = index === messages?.length - 1;
  const previousMessage = !isFirstMessage && messages[index - 1];
  const nextMessage = !isLastMessage && messages[index + 1];

  const isStartOfInterval =
    isFirstMessage ||
    !isWithinOneMinute(previousMessage?.createdAt, messageObj?.createdAt);
  const isEndOfInterval =
    isLastMessage ||
    !isWithinOneMinute(messageObj?.createdAt, nextMessage?.createdAt);

  return (
    <li className="repaly d-flex justify-content-end ">
      <div>
        <div>
          {" "}
          {messageObj?.image ? (
            <img
              src={messageObj?.image}
              width={100}
              height={100}
              style={{ marginRight: isStartOfInterval ? "20px" : "55px" }}
            />
          ) : (
            ""
          )}
        </div>
        {messageObj?.message ? (
          <p className={`margin ${isStartOfInterval ? "" : "intervalGap"}`}>
            {messageObj?.message}
          </p>
        ) : ""}

        {isEndOfInterval && (
          <span className={`time ${isStartOfInterval ? "" : "intervalGap"}`}>
            {getTimeDiffOutput(messageObj?.createdAt)}
          </span>
        )}
      </div>
      {isStartOfInterval && (
        <img className="msg-userImg" src={userimg} alt="" />
      )}
    </li>
  );
}
