import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useDispatch } from "react-redux";
import { getBooking, getUserRoleProvider } from "../../../redux/slices/web";
import { useWebSelector } from "../../../redux/selector/web";
import moment from "moment";
import { bookingStatus } from "../../../utilities/helper";

function Bookings() {
  const [userId, setUserId] = useState();
  const [providerId, setProviderId] = useState();
  const [activeTab, setActiveTab] = useState("all");
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [providerName,setProviderName]  = useState("All");

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };
  const tabs = ["All", "Active", "Upcoming", "Completed", "Cancelled"];

  const handleClick = (tab) => {
    setCurrentPage(1)
    setActiveTab(tab.toLowerCase());
  };
  const webData = useWebSelector();
  const BookingData = webData?.bookingData?.jobs || [];
  const userList = webData?.userList || [];

  const dispatch = useDispatch();

  const getBookingList = () => {
    let params = {
      page: currentPage,
      limit: 10,
      flag: activeTab.toLowerCase(),
      providerId: providerId,
      userId: userId
    };
    dispatch(
      getBooking({
        ...params,
        cb(res) {
          setPageCount(res?.data?.data?.total_pages);
        },
      })
    );
  };

  const GetUserRoleProvider = () => {
    dispatch(
      getUserRoleProvider({
        role: "provider",
        cb(res) { 
          console.log(res,"responsedataforuserProvider")
        },
      })
    );
  };

  useEffect(() => {
    GetUserRoleProvider();
  }, []);

  useEffect(() => {
    getBookingList();
  }, [currentPage, activeTab, providerId, userId]);

  const handleBookingByProvider = (item) => {
    setProviderId(item?._id) 
    setProviderName(item ? item?.userInfo?.name :"All")
    setCurrentPage(1)
  }

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Bookings</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <section className="mb-2">
        <div className="container-fluid">
          <div className="row">
            <div> </div>

            <div className=" mt-4  d-flex justify-content-between">
              <div className="d-flex mt-2">
                <ul className="booking_filter">
                  {tabs.map((tab) => (
                    <li
                      key={tab}
                      className={`ml-3 ${activeTab === tab.toLowerCase() ? "active" : ""}`}
                      onClick={() => handleClick(tab)}
                    >
                      {tab}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex mt-2 " style={{ marginRight: "62px" }}>
                <div className="d-flex commonFilterHeader" onClick={() => document.getElementById('AssigneeDropdown').classList.toggle('show')}>
                  <p className="commonBox_Heading m-0">Assignee({providerName})</p>
                  <div className="dropdown filterDropdown userListFilter">
                    <button
                      className="filterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-filter"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end  my_dropbox" id="AssigneeDropdown">
                      <li>
                        <a
                          className="dropdown-item font-14 subtext"
                          onClick={(e) => handleBookingByProvider(undefined)}
                        >
                          All
                        </a>
                      </li>
                      {userList && userList.length > 0 && userList.map(item => (<li>
                        <a
                          className="dropdown-item font-14 subtext"
                          onClick={(e) => handleBookingByProvider(item)}
                        >
                          {item?.userInfo?.name}
                        </a>
                      </li>))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content commonBox_  userlistbox">
        <div className="container-fluid">
          <table className="usertable">
            <tr className="tableborder">
              <th>Date</th>
              <th>Service</th>
              <th>Customer</th>
              <th>Assigned to</th>
              <th>Status</th>
              <th>Total</th>
            </tr>
            {webData?.loading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center ">
                      <div className="spinner-grow " role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {BookingData && BookingData.length > 0 && (
                  <>
                    {BookingData?.map((data, index) => (
                      <tr key={`booking_list_${index}`}>
                        <td>
                          <div>
                            {moment(data?.date).format("MMMM D, YYYY") ?? "---"}
                          </div>
                          <span>
                            {data?.time?.from} - {data?.time?.to}
                          </span>
                        </td>
                        <td >{data?.service?.name}</td>
                        <td >{data?.userId?.userInfo?.name} </td>
                        <td >{data?.providerId?.userInfo?.name ?? "---"} </td>
                        <td >{bookingStatus[data.status]}</td>
                        <td >{data?.paymentDetail?.payableAmount}</td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </table>

          <div className="col-12 ">
            {!webData?.loading && BookingData && BookingData.length == 0 &&
              <div className="d-flex justify-content-center py-3">
                <p className="p-2">No bookings found</p>
              </div>}
          </div>
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {BookingData?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Bookings;
