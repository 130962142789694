import React, { memo, useEffect, useState, useRef } from "react";
import * as images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import { useChatSelector } from "../../../../redux/selector/chat";
import { useAuthSelector } from "../../../../redux/selector/auth";
import {
  getAllMessages,
  getOneAllMessages,
  sendMessage,
  setNewMessage,
  markRead,
} from "../../../../redux/slices/chat";
import LeftBlock from "./MessageBlocks/LeftBlock";
import RightBlock from "./MessageBlocks/RightBlock";
import MessageHeader from "./Header";
import ServiceDetails from "./ServiceDetails";
import { toast } from "react-toastify";
import BaseUrl from "../../../../constants/baseUrl";
import { uploadFile } from "../../../../redux/slices/web";
import { socketEvents } from "../../../../utilities/helper";
import { io } from "socket.io-client";

const MessageHeaderMemoized = memo((props) => <MessageHeader {...props} />);
const ServiceDetailsMemoized = memo((props) => <ServiceDetails {...props} />);
const LeftBlockMemoized = memo((props) => <LeftBlock {...props} />);
const RightBlockMemoized = memo((props) => <RightBlock {...props} />);

export default function MesssageScreens(props) {
  const dispatch = useDispatch();
  const chatSelector = useChatSelector();
  const authSelector = useAuthSelector();
  let activeConversation = chatSelector.activeConversation;
  let currentUser = authSelector.loggedInUser;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [userNames, setUserNames] = useState("");
  const [userImages, setUserImages] = useState([]);

  const [imageSent, setImageSent] = useState();
  const [messagetext, setMessageText] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchMessages = (page) => {
    setButtonLoading(true);
    if (activeConversation) {
      let params = {
        page: page,
        limit: 5,
        conversationId: activeConversation,
      };
      dispatch(
        getAllMessages({
          ...params,
          cb(res) {
            console.log("getAllMessages");
            setPageCount(res?.data?.data?.total_pages);
            setHasMore(page < res?.data?.data?.total_pages);
            setButtonLoading(false);
          },
        })
      );
    }
  };


  const getInitialMessage = () => {
    let params = {
      page: page,
      limit: 10,
      conversationId: activeConversation,
    };
    let nameArray = [];
    let imageArray = [];
    chatSelector.conversationsById.participantsDetails.forEach((item) => {
      if (item._id !== currentUser.user_id) {
        nameArray.push(item.userInfo.name);
        imageArray.push(item.userInfo.profilePhoto);
      }
    });

    let names = nameArray.join(", ");
    setUserNames(names);
    setUserImages(imageArray);
    dispatch(
      getOneAllMessages({
        ...params,
        cb(res) {
          bottomOfPage();
          setPageCount(res?.data?.data?.total_pages);
          setHasMore(page < res?.data?.data?.total_pages);
        },
      })
    );
  };

  let socket = null;

  const initializeSocket = () => {
    socket = io(process.env.REACT_APP_API_URL, {
      path: "/api/v1/connect",
      query: {
        conversationId: activeConversation,
      },
    });
    socket.on("connect", () => {
      console.log("user connected", socket.id);
    });

    socket.on(socketEvents.messageRecieve, (message) => {
      dispatch(setNewMessage(message));
      dispatch(
        markRead({
          conversationId: activeConversation,
          cb(res) { },
        })
      );
    });
    socket.on("disconnect", () => {
      console.log("user disconnected");
    });
  };

  useEffect(() => {
    if (activeConversation) {
      getInitialMessage();
      initializeSocket();
      // const eventStream = initializingEventSource(activeConversation);
      // eventStream.onmessage = (event) => {
      //   const parsedData = JSON.parse(event.data);
      //   if (parsedData) setNewMessagesFunc(parsedData);
      // };
      return () => {
        socket.disconnect();
        // eventStream.close();
      };
    }
  }, [activeConversation]);

  const initializingEventSource = (activeConversation) => {
    const events = new EventSource(
      `${BaseUrl.API_URL}/messages/events/${activeConversation}`
    );
    return events;
  };

  const handleChange = (event) => {
    setMessageText(event.target.value);
  };

  const submitMessage = () => {
    if (!messagetext || messagetext.trim() === "") {
      toast.info("Please enter some text");
      return;
    }
    let apiParams = {
      message: messagetext || undefined,
      conversationId: chatSelector.conversationsById._id,
      image: imageSent || undefined,
    };
    dispatch(
      sendMessage({
        ...apiParams,
        cb(res) {
          bottomOfPage();
          setMessageText("");
          setImageSent(null);
        },
      })
    );
  };

  const handleImageUploadClick = () => {
    document.getElementById("imageUploadInput").click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let params = { file };
      dispatch(
        uploadFile({
          ...params,
          cb(res) {
            if (res.status === 200) {
              const filePath = res?.data?.data?.fileUrl;
              setImageSent(filePath);
              props?.close();
              props?.getallguides();
            } else {
              console.log("Something Went Wrong");
            }
          },
        })
      );
    }
  };

  const handleDelete = () => {
    setImageSent(null);
  };
  
  const messageListRef = useRef(null);

  const loadMoreMessages = () => {
    console.log(buttonLoading, "buttonLoadingLoadMoreMessages");

    if (page < pageCount) {
      setPage(page + 1);
      fetchMessages(page + 1);
      setTimeout(() => {
        const element = document.getElementById("messagelist");
        if (element) {
          element.scrollTop = 0;
        }
      }, 100);
    }
  };

  const bottomOfPage = () => {
    var element = document.getElementById("messagelist");
    if (!element) return;
    element.scrollTop = element.scrollHeight;
    var lastMessage = element.lastElementChild;
    if (lastMessage) {
      lastMessage.scrollIntoView({ block: "end" });
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitMessage();
    }
  };

  return (
    <>
      <MessageHeaderMemoized
        imageArray={userImages}
        names={userNames}
        getAllMessagess={fetchMessages}
      />
      <div className="modal-body">
        <div className="msg-body">
          <ServiceDetailsMemoized
            serviceDetail={chatSelector?.conversationsById?.serviceDetail}
            bookingDetail={chatSelector?.conversationsById?.bookingDetail}
          />

          <div id="chat-window">
            <div className="load_more_button">
              {" "}
              <button
                onClick={loadMoreMessages}
                disabled={!hasMore}
                className="load-btn"
              >
                {console.log(buttonLoading, "buttonLoadingggg")}
                {buttonLoading
                  ? "Fetching..."
                  : hasMore
                    ? "Load More Messages"
                    : "No more messages"}
              </button>
            </div>

            <ul
              className="p-0"
              id="messagelist"
              ref={messageListRef}
              style={{
                overflowY: "scroll",
              }}
            >
              {chatSelector.fetchedMessages &&
                chatSelector.fetchedMessages.length > 0 ? (
                chatSelector.fetchedMessages.map((msg, msgIndex) =>
                  msg?.byUser === currentUser?.user_id ? (
                    <RightBlockMemoized
                      messageObj={msg}
                      messages={chatSelector.fetchedMessages}
                      index={msgIndex}
                      key={`rightMessage_${msgIndex}`}
                    />
                  ) : (
                    <LeftBlockMemoized
                      messageObj={msg}
                      index={msgIndex}
                      currentUser={currentUser}
                      messages={chatSelector.fetchedMessages}
                      key={`leftMessage_${msgIndex}`}
                    />
                  )
                )
              ) : (
                <div className="noMessageDiv">No Messages</div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="image-outer">
        {imageSent ? (
          <>
            <div className="position-relative">
              <div className="cross-image" onClick={handleDelete}>
                <img src={images.cross} />
              </div>
              <div>
                <img src={imageSent} alt="upload_image" className="image" />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="send-box">
        <input
          type="text"
          name="message"
          value={messagetext}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="form-control"
          placeholder="Write message…"
        />
        <button type="button" onClick={submitMessage}>
          <i className="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
        <a href="#" onClick={handleImageUploadClick}>
          <i className="fa-regular fa-image imageUpload"></i>
        </a>
        <input
          type="file"
          id="imageUploadInput"
          style={{ display: "none" }}
          // ref={fileInputRef}
          onChange={handleImageChange}
        />
      </div>
    </>
  );
}
