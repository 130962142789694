import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  industry: {},
  industryById: {},
  getOneAccountInfo: {},
  providerService: {},
  Bookings: {},
  UserRoleProvider: {},
  OneSubService: {},
  supportData: {}
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    getAllUser: (state) => {
      state.loading = true;
    },
    setGetAllUser: (state, action) => {
      state.loading = false;
    },
    updateActiveInactiveStatus: (state) => {
      state.loading = true;
    },
    setUpdateActiveInactiveStatus: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    getAllIndustry: (state) => {
      state.loading = true;
    },
    setAllIndustry: (state, action) => {
      state.loading = false;
      state.industry = action?.payload ?? {};
    },
    addIndustry: (state) => {
      state.loading = true;
    },
    editIndustry: (state) => {
      state.loading = true;
    },
    deleteIndustry: (state) => {
      state.loading = true;
    },
    uploadFile: (state) => {
      state.loading = true;
    },
    getDashboardData: (state) => {
      state.loading = true;
    },
    privacyPolicy: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setPrivacyPolicy: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    getTermsList: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setGetTermsList: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    privacyPolicyUpdate: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setPrivacyPolicyUpdate: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    getIndustryById: (state, action) => {
      state.loading = true;
      state.industryById = {};
    },
    setIndustryDetailsById: (state, action) => {
      state.loading = false;
      state.industryById = action.payload;
    },
    getOneAccountDetail: (state, action) => {
      state.loading = true;
    },
    setOneAcountDetail: (state, action) => {
      state.loading = false;
      state.getOneAccountInfo = action.payload;
    },
    deleteUser: (state, action) => {
      state.loading = true;
    },
    setDeleteUser: (state, action) => {
      state.loading = false;
    },
    getproviderService: (state, action) => {
      state.loading = true;
    },
    setProviderService: (state, action) => {
      state.loading = false;
      state.providerService = action.payload;
    },
    getBooking: (state, action) => {
      state.loading = true;
    },
    setBooking: (state, action) => {
      state.loading = false;
      state.bookingData = action.payload;
    },
    getUserRoleProvider: (state, action) => {
      state.loading = true;
    },
    setUserRoleProvider: (state, action) => {
      state.loading = false;
      state.userList = action.payload;
    },
    getOneSubServices: (state, action) => {
      state.loading = true;
    },
    setOneSubservices: (state, action) => {
      state.loading = false;
      state.OneSubService = action.payload
    },
    getSubServicesUpdate: (state, action) => {
      state.loading = true;
    },
    setSubServicesUpdate: (state, action) => {
      state.loading = false;
    },
    getSupport: (state, action) => {
      state.loading = true;
    },
    updateSupport: (state, action) => {
      state.loading = true;
    },
    supportSaved: (state, action) => {
      state.loading = false;
    },
    setSupport: (state, action) => {
      state.loading = false;
      state.supportData = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  getAllUser,
  setGetAllUser,
  updateActiveInactiveStatus,
  setUpdateActiveInactiveStatus,
  getAllIndustry,
  addIndustry,
  setAllIndustry,
  onErrorStopLoad,
  uploadFile,
  editIndustry,
  deleteIndustry,
  getDashboardData,
  privacyPolicy,
  setPrivacyPolicy,
  privacyPolicyUpdate,
  setPrivacyPolicyUpdate,
  getIndustryById,
  setIndustryDetailsById,
  getTermsList,
  setGetTermsList,
  getOneAccountDetail,
  setOneAcountDetail,
  deleteUser,
  setDeleteUser,
  getproviderService,
  setProviderService,
  getBooking,
  setBooking,
  getUserRoleProvider,
  setUserRoleProvider,
  getOneSubServices,
  setOneSubservices,
  getSubServicesUpdate,
  setSubServicesUpdate,
  getSupport,
  updateSupport,
  supportSaved,
  setSupport
} = webSlice.actions;

export default webSlice.reducer;
