import React, { useEffect, useState, useRef } from "react";
import * as Images from "../../../utilities/images.js";
import { toast } from "react-toastify";

const CreateRecrutingModal = (props) => {
  const { editData, addNewRecursion, close, reset } = props;
  const [frequency, setFrequency] = useState("daily");
  const [frequencyValue, setFrequencyValue] = useState(1);
  const [title, setTitle] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const discountRef = useRef(null);
  const handleSave = () => {
    if (discountPercentage > 100) {
      toast.error("Discount Percentage Must be less then 100");
    } else if (title == "") {
      toast.error("Name is required");
    } else if (frequency !== "onetime" && frequencyValue == 0) {
      toast.error("Frequency is required");
    } else {
      addNewRecursion({
        frequency,
        frequencyValue,
        title,
        discountPercentage,
      });
      close();
    }
  };

  useEffect(() => {
    if (editData) {
      setFrequency(editData.frequency);
      setFrequencyValue(editData.frequencyValue);
      setTitle(editData.title);
      setDiscountPercentage(editData.discountPercentage);
    } else {
      if (frequencyValue === 0 || frequencyValue === 1) {
        setTitle(`Every ${freqtitle[frequency]}`);
      }
    }
  }, [editData]);

  const freqtitle = {
    daily: "day",
    weekly: "week",
    monthly: "month",
  };


  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
    };
    const inputElement = discountRef.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <>
      <form>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label customLables">Frequency</label>
            <div className="position-relative">
              <img src={Images.UpDownArrow} className="upDownArrow" />
              <select
                className="form-select customSelect"
                aria-label="Default select example"
                value={frequency}
                onChange={(e) => {
                  if (e.target.value === "onetime") {
                    setFrequencyValue(0);
                    setTitle("One Time");
                    setDiscountPercentage(0);
                  } else setTitle(`Every ${(frequencyValue <= 1 ? "" : frequencyValue)} ${freqtitle[e.target.value]}`)
                  setFrequency(e.target.value);
                }}
              >
                <option value="onetime">One Time</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
          {frequency !== "onetime" && (
            <div className="col-md-6 mb-3">
              <label className="form-label invisible">Name</label>
              <div className="d-flex">
                <span className="everySpan">Every</span>
                <input
                  type="text"
                  className="form-control customControl everyMonthInput"
                  placeholder="1"
                  disabled={frequency === "onetime" ? true : false}
                  onChange={(e) => {
                    setFrequencyValue(e.target.value);
                    if (e.target.value === "0" || e.target.value === "1") {
                      setTitle(`Every ${freqtitle[frequency]}`);
                    } else if (e.target.value > 1) {
                      setTitle(
                        `Every ${e.target.value} ${freqtitle[frequency]}`
                      );
                    }
                  }}
                  value={frequencyValue}
                />
                <span className="monthlySpan">{frequency}</span>
              </div>
            </div>
          )}
        </div>
        {frequency !== "onetime" && (
          <div className="mb-3">
            <label className="form-label customLables">Name</label>
            <input
              type="text"
              className="form-control customControl"
              placeholder=""
              disabled={frequency === "onetime" ? true : false}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
            />
          </div>
        )}
        {frequency !== "onetime" && (
          <div className="mb-5">
            <label className="form-label customLables">Discount (%)</label>
            <input
              type="number"
              className="form-control customControl"
              disabled={frequency === "onetime" ? true : false}
              placeholder="%"
              min="0"
              onChange={(e) => {
                setDiscountPercentage(e.target.value);
              }}
              value={discountPercentage}
              ref={discountRef}
            />
          </div>
        )}
        <div className="row">
          <div className="col-md-6 ">
            <button
              type="button"
              className="cancelBtn mw-100"
              onClick={() => {
                close();
                reset();
              }}
            >
              CANCEL
            </button>
          </div>
          <div className="col-md-6">
            <button
              type="button"
              className="addBtn mw-100"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateRecrutingModal;
