export const encodeData = (data, secretKey) => {
    const encodedData = btoa(data);
    return encodedData
}
export const decodeData = (encodedData, secretKey) => {
    const decodedData = atob(encodedData);
    return decodedData
}

export const onlyNumbersRegex = /^[0-9]+$/;

export const blockInvalidChar = (e) => {
    if (!onlyNumbersRegex.test(e.key)) e.preventDefault();
};


export const getTimeDiffOutput = (givenTimeStr) => {
    const givenTime = new Date(givenTimeStr);
    const currentTime = new Date();
    const timeDiffMs = currentTime - givenTime;
    const timeDiffHrs = timeDiffMs / (1000 * 60 * 60);
    if (timeDiffHrs < 1) {
        return givenTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }
    else if (timeDiffHrs < 24) {
        return `${timeDiffHrs.toFixed(0)} hour ago`

    } else if (timeDiffHrs >= 24 && timeDiffHrs < 48) {
        return "Yesterday";
    } else if (timeDiffHrs >= 48 && timeDiffHrs < 168) {
        const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        return daysOfWeek[givenTime.getDay()];
    } else {
        return givenTime.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
};

export const socketEvents = {
    messageRecieve: 'message_recieve',
}

export const bookingStatus = {
    "0": "Pending",
    "1": "Accepted",
    "2": "On The Way",
    "3": "Reached",
    "4": "Completed",
    "5": "Cancelled",
}

export const masterDataTypes = {
    providerShare: 'provider_share',
    adminShare: 'admin_share',
}

export const userRoles = {
    all: 'All',
    client: 'client',
    customer: 'client',
    provider: 'provider',
    admin: 'admin',
    customerKey: 'customer'
}

export const userRoleName = {
    client: "Customer",
    provider: "Provider",
    admin: "Admin"
}