import { toast } from "react-toastify"
import ApiPath from "../../../constants/apiPath"
import { ApiClient } from "../../../utilities/api"
import { all, call, put, take, takeLatest } from "redux-saga/effects"
import { onErrorStopLoad, setAllCategories, setAllServices, setUpdateCategories } from "../../slices/services"

function* getAllServices(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.get, action.url = ApiPath.service.GET_ALL_SERVICE, (action.params = { params: dataToSend }))
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
            yield put(setAllServices(action.payload.data))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* getAllCategories(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.get, action.url = ApiPath.category.GET_ALL_CATEGORIES, (action.params = { params: dataToSend }))
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
            yield put(setAllCategories(action.payload.data))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* createCategories(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.post, action.url = ApiPath.category.ADD_CATEGORIES, dataToSend)
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* updateCategories(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.id;
        delete dataToSend.cb;
        const resp = yield call(ApiClient.put, `${ApiPath.category.ADD_CATEGORIES}${action.payload.id}`, dataToSend);
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
            yield put(setUpdateCategories(action.payload.data))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}



function* createServices(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.post, action.url = ApiPath.service.ADD_SERVICES, dataToSend)
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* deleteService(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.delete, action.url = `${ApiPath.service.DELETE_SERVICE}${dataToSend.serviceId}`)
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* deleteCategory(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.delete, action.url = `${ApiPath.category.DELETE_CATEGORIES}${dataToSend.categoryId}`)
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* serviceSaga() {
    yield all([takeLatest("service/getAllServices", getAllServices)])
    yield all([takeLatest("service/createServices", createServices)])
    yield all([takeLatest("service/getAllCategories", getAllCategories)])
    yield all([takeLatest("service/createCategories", createCategories)])
    yield all([takeLatest("service/deleteService", deleteService)])
    yield all ([takeLatest("service/deleteCategory",deleteCategory)])
    yield all([takeLatest("service/updateCategories",updateCategories)])
}


export default serviceSaga