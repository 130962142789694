import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDashboardData } from "../../redux/slices/web";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utilities/helper";

const cardKeys = {
  transfer: 'transfer',
  payment: 'payment',
  refunded: 'refunded'
}

const Dashboard = () => {
  document.title = "Home";
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);
  useEffect(() => {
    dispatch(
      getDashboardData({
        cb(res) {
          if (res.status === 200) {
            setDashboardData(res.data.data);
          }
        },
      })
    );
  }, []);
  const navigate = useNavigate();

  const getCardCounts = (key) => {
    let count = 0
    if (dashboardData && Array.isArray(dashboardData?.payments)) {
      let countObject = dashboardData?.payments.find(item => item._id == key)
      count = countObject?.totalAmount || 0
      count = count.toFixed(2)
    }
    return count
  }

  const getCardStatusCounts = (key) => {
    let count = 0
    if (dashboardData && Array.isArray(dashboardData?.paymentsByStatus)) {
      let countObject = dashboardData?.paymentsByStatus.find(item => item._id == key)
      count = countObject?.totalAmount || 0
      count = count.toFixed(2)
    }
    return count
  }

  const getUserCountByRoles = (role) => {
    let count = 0
    if (dashboardData && Array.isArray(dashboardData?.users) && dashboardData?.users.length > 0) {
      if (dashboardData && Array.isArray(dashboardData?.users[0]['roles'])) {
        let countObject = dashboardData?.users[0]['roles'].find(item => item.role == role)
        count = countObject?.count || 0
      }
    }
    return count

  }



  return (
    <div className="content-wrapper ">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="dashContent">
        {/* Main content */}
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                navigate("/manage-account?role=customer");
              }}
            >
              <div className="small-box dashboxcolor">
                <div className="inner">
                  <h3>{getUserCountByRoles(userRoles.client)}</h3>
                  <p>Customer</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <a className="small-box-footer">
                  <i className="fas "></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                navigate("/manage-account?role=provider");
              }}
            >
              <div className="small-box dashboxcolor_two">
                <div className="inner">
                  <h3>{getUserCountByRoles(userRoles.provider)}</h3>
                  <p>Provider</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <a className="small-box-footer">
                  <i className="fas "></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                navigate("/service");
              }}
            >
              <div className="small-box dashboxcolor">
                <div className="inner">
                  <h3>
                    {Array.isArray(dashboardData?.serviceCount)
                      ? dashboardData?.serviceCount[0]?.count || 0
                      : "0"}
                  </h3>
                  <p>Services</p>
                </div>
                <div className="icon">
                  <i className="fas fa-bread-slice"></i>{" "}
                </div>
                <a className="small-box-footer">
                  <i className="fas "></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                navigate("/transactions");
              }}
            >
              <div className="small-box dashboxcolor_two">
                <div className="inner">
                  <h3>
                    ${getCardCounts(cardKeys.payment)}
                  </h3>
                  <p>Customer Payments for Services</p>
                </div>
                <div className="icon">
                  <i class="fa-solid fa-dollar-sign"></i>
                </div>
                <a className="small-box-footer">
                  <i className="fas "></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                navigate("/transactions");
              }}
            >
              <div className="small-box dashboxcolor">
                <div className="inner">
                  <h3>
                    ${getCardCounts(cardKeys.transfer)}
                  </h3>
                  <p>Provider Payments for Services</p>
                </div>
                <div className="icon">
                  <i class="fa-solid fa-dollar-sign"></i>
                </div>
                <a className="small-box-footer">
                  <i className="fas "></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                navigate("/transactions");
              }}
            >
              <div className="small-box dashboxcolor_two">
                <div className="inner">
                  <h3>
                    ${getCardStatusCounts(cardKeys.refunded)}
                  </h3>
                  <p>Total Amount Refunded</p>
                </div>
                <div className="icon">
                  <i class="fa-solid fa-dollar-sign"></i>
                </div>
                <a className="small-box-footer">
                  <i className="fas "></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
