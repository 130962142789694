import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { webSlice } from "./web";
import { transactionSlice } from "./transaction"
import { chatSlice } from "./chat";
import { serviceSlice } from "./services";

export const mainReducer = combineReducers({
  web: webSlice.reducer,
  auth: authSlice.reducer,
  chat: chatSlice.reducer,
  service: serviceSlice.reducer,
  transaction: transactionSlice.reducer,
});
