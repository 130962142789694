import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  enterOtp,
  resendEnterOtp,
  onErrorStopLoad,
} from "../../redux/slices/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useAuthSelector } from "../../redux/selector/auth";
import * as images from "../../utilities/images";

const EnterOtp = () => {
  const location = useLocation();
  let email = location?.state?.email;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const [otp, setOtp] = useState("");
  
  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      toast.error("Please enter your OTP"); 
      return;
    }
    let params = {
      // email :email,
      otp: otp,
    };
    dispatch(
      enterOtp({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/reset-password",{ state: { user_id: ress?.data?.data?.user_id } });
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // resend OTP

  const handleResendOtp = (e) => {
    e.preventDefault();
    let params = {
      email: email,
      type: "email"
    };
    dispatch(
      resendEnterOtp({
        ...params,
        cb(res) {},
      })
    );
  };

  return (
    <>
      <div className="login-page authBg adminotp">
          <div className="card card-outline card-primary cardTopOutline cardBg">
            <div className="loginPage">
            <div className="d-flex align-items-center justify-content-center">
            <img
              src={images.OrigoLogo}
              className="brand_logo"
              alt="Brand Logo"
              style={{ width: "200px" }}
            />
          </div>
              <div className="card-header text-center">
                <h3 className="mb-0 logintext">Enter OTP</h3>
              </div>
              <div className="card-body login-card-body mt-4">
                <form onSubmit={(e) => handleSubmit(e)} className="otpform">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={(props) => (
                      <input {...props} className="enterOtp" />
                    )}
                  />

                  <p className="mb-3 mt-3 otpheading">
                    Don’t Received{" "}
                    <a
                      onClick={(e) => handleResendOtp(e)}
                      className="resendLink "
                      href="/auth/otp"
                    >
                      Resend
                    </a>{" "}
                  </p>

                  <div className="buttonBox mt-5">
                    <button
                      type="submit"
                      className="loginBtnCommon btnYellow mt-3 mw-100 loginbtn"
                    >
                      {authData.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp; Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default EnterOtp;
