import React from "react";
import { toast } from "react-toastify";

function IntakeQuestions(props) {
  const {
    values,
    handleChange,
    setValues,
    submit,
    close,
    reset,
    intakeQuestionIndex,
  } = props;

  const validate = () => {
    if (values?.question.trim() === "") {
      toast.error("Please enter the question");
      return;
    } else if (values?.description.trim() === "") {
      toast.error("Please enter the question description");
      return;
    }
    else {
      submit();
    }
  };

  return (
    <>
      <div className="addSubServiceOuter noshadow">
        <div className="row">
          <div className="mb-3 col-md-12">
            <label className="form-label customLables">Question</label>
            <input
              type="text"
              className="form-control customControl"
              placeholder="Enter Question"
              name="question"
              value={values?.question}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3 col-md-12">
            <label className="form-label customLables">Description</label>
            <input
              type="text"
              className="form-control customControl"
              placeholder="Enter Description"
              name="description"
              value={values?.description}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3 col-md-12">
            <label className="form-label customLables">Placeholder</label>
            <input
              type="text"
              className="form-control customControl"
              placeholder="Enter Placeholder"
              name="placeholder"
              value={values?.placeholder}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <input
              type="checkbox"
              id="intakeQuestion"
              checked={values.isRequired}
              onChange={(e) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  isRequired: e.target.checked,
                }))
              }
            />
            <label htmlFor="intakeQuestion">Required</label>
          </div>

          <button
            className="cancelBtn me-4"
            onClick={(e) => {
              e.preventDefault();
              reset();
              close();
            }}
          >
            CANCEL
          </button>
          <button className="addBtn" type="button" onClick={validate}>
            {intakeQuestionIndex !== null ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </>
  );
}

export default IntakeQuestions;
