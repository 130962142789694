import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import FullPageloader from "../../../common/FullPageloader";
import { useServiceSelector } from "../../../redux/selector/service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getOneSubServices,
  getSubServicesUpdate,
  uploadFile,
} from "../../../redux/slices/web";
import * as Images from "../../../utilities/images.js";
import { decodeData } from "../../../utilities/helper.js";
import CustomModal from "../../Modal/CustomModal";
import CreateRecrutingModal from "../manageIndutry/createRecrutingModal.js";
import ServiceModifiers from "./modalScreens/ServiceModifiers.jsx";
import IntakeQuestions from "./modalScreens/IntakeQuestions.jsx";
import ServiceModifiersOptions from "./modalScreens/ServiceModifiersOptions.jsx";
import ServiceConstants from "./serviceConst.json";
import routesConstants from "../../../constants/routes.json";
import { toast } from "react-toastify";
import modalFlags from "../../../constants/modalFlags.json";


export default function ModifyService(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useParams();
  const { serviceSlug } = queryParams;

  const serviceSelector = useServiceSelector();
  const isLoading = serviceSelector.loading;

  const [recurringEditData, setRecurringEditData] = useState(null);
  const [key, setKey] = useState(Math.random());
  const [recurringEditIndex, setRecurringEditIndex] = useState(null);
  const [assignmentType, setAssignmentType] = useState("automatically");
  const [serviceImage, setServiceImage] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setKey(Math.random());
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const submitRecurring = (values) => {
    if (recurringEditIndex !== null) {
      setRecurringOptions((prev) => {
        let options = [...prev];
        options[recurringEditIndex] = values;
        return options;
      });
      setRecurringEditData(null);
      setRecurringEditIndex(null);
    } else {
      setRecurringOptions((prev) => [...prev, { ...values }]);
    }
  };

  const handleServiceImage = (event) => {
    let params = {
      file: event.target.files[0],
    };
    var response = dispatch(
      uploadFile({
        ...params,
        cb(res) {
          setServiceImage(res?.data?.data?.fileUrl);
        },
      })
    );
  };

  const recurringEdit = (data, index) => {
    setRecurringEditIndex(index);
    setRecurringEditData(data);
    setModalDetail({
      show: true,
      flag: modalFlags.recurring,
    });
    setKey(Math.random());
  };

  const handlerecurringOptionsRemove = (index) => {
    setRecurringOptions((prev) => {
      const List = [...prev];
      return List.filter((item, i) => {
        return index != i;
      });
    });
  };

  const handleOptionChange = (e) => {
    setAssignmentType(e.target.value);
  };

  const [modifiers, setModifiers] = useState([]);
  const [intakequestion, setintakequestion] = useState({});
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringOptions, setRecurringOptions] = useState([]);

  const [formValues, setFormValues] = useState({
    name: "",
    image: "",
    description: "",
    price: "",
    isHidden: false,
    taxable: false,
    taxDetails: {
      name: "",
      rate: 0,
    },
    isRecurring: true,
  });

  // Predefined Values
  const [taxDetails, setTaxDetails] = useState({
    name: "",
    rate: "",
  });
  const [modifierDetail, setModifierDetail] = useState({
    title: "",
    description: "",
    isRequired: false,
    selectionType: "single",
  });
  const [modifierOptionValue, setModifierOptionValue] = useState({
    title: "",
    description: "",
    price: "",
    allowComment: false,
    commentPlaceholder: "",
  });
  const [intakeQuesValue, setIntakeQuestValue] = useState({
    question: "",
    description: "",
    placeholder: "",
    isRequired: false,
  });

  // edit Index
  const [modifierIndex, setModifierIndex] = useState(null);
  const [modifierOptionIndex, setModifierOptionIndex] = useState(null);
  const [intakeQuestionIndex, setIntakeQuestionIndex] = useState(null);

  // Handle change functions
  const handleFormValuesChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleTaxValuesChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setTaxDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleModifierChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setModifierDetail((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleModifierOptionChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setModifierOptionValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleIntakeChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setIntakeQuestValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Reset Value Functions
  const resetModifierDetails = () => {
    setModifierDetail({
      title: "",
      description: "",
      selectionType: "single",
      isRequired: false,
    });
    setModifierIndex(null)
  };
  const resetModifierOptions = () => {
    setModifierOptionValue({
      title: "",
      description: "",
      price: "",
      allowComment: false,
      commentPlaceholder: "",
    });
    setModifierIndex(null);
    setModifierOptionIndex(null);
  };
  const resetIntakeQuestions = () => {
    setIntakeQuestValue({
      question: "",
      description: "",
      placeholder: "",
      isRequired: false,
    });
    setIntakeQuestionIndex(null)
  };
  const resetRecurringData = () => {
    setRecurringEditData(null);
    setRecurringEditIndex(null);
  }

  // Submit Values Function
  const modifierAdd = (event) => {
    setModifiers((prev) => {
      let prevArray = [...prev];
      if (modifierIndex !== null) {
        prevArray[modifierIndex] = modifierDetail;
      } else {
        prevArray.push({
          ...modifierDetail,
          options: [],
        });
      }
      return prevArray;
    });
    resetModifierDetails();
    handleOnCloseModal();
  };
  const modifierOptionAdd = (event) => {
    let modifierArr = [...modifiers];

    let newValues = { ...modifierArr[modifierIndex] };

    let modifierOptions = [...newValues?.options];

    if (modifierOptionIndex !== null) {
      modifierOptions[modifierOptionIndex] = modifierOptionValue;
      newValues = {
        ...newValues,
        options: modifierOptions,
      };
      modifierArr[modifierIndex] = newValues;
    } else {
      modifierOptions.push(modifierOptionValue);
      modifierArr[modifierIndex] = { ...newValues, options: modifierOptions };
    }
    setModifiers(modifierArr);
    resetModifierOptions();
    handleOnCloseModal();
    setModifierIndex(null);
    setModifierOptionIndex(null);
  };
  const intakeQuestionAdd = (event) => {
    setintakequestion((prev) => {
      let prevArray = [...prev];
      if (intakeQuestionIndex !== null) {
        prevArray[intakeQuestionIndex] = intakeQuesValue;
        setIntakeQuestionIndex(null);
      } else {
        prevArray.push(intakeQuesValue);
      }
      return prevArray;
    });

    resetIntakeQuestions();
    handleOnCloseModal();
  };

  useEffect(() => {
    let params = {
      serviceId: decodeData(serviceSlug),
    };
    dispatch(
      getOneSubServices({
        ...params,
        cb(res) {
          if (res.status == 200) {
            const serviceDetail = res?.data?.data;
            let tempFormValues = {};
            let notToInclude = [
              "slug",
              "_id",
              "assignmentMethod",
              "isPending",
              "isDeleted",
              "categoryId",
              "createdAt",
              "updatedAt",
              "__v",
            ];
            Object.keys(serviceDetail).forEach((key) => {
              if (notToInclude.includes(key)) return;
              if (key === "isRecurring") {
                setIsRecurring(serviceDetail[key]);
              } else if (key === "modifiers") {
                setModifiers(serviceDetail[key]);
              } else if (key === "intakeQuestions") {
                setintakequestion(serviceDetail[key]);
              } else if (key === "taxDetails") {
                setTaxDetails(serviceDetail[key]);
              } else if (key === "recurringOptions") {
                setRecurringOptions(serviceDetail[key]);
              } else if (key === "image") {
                setServiceImage(serviceDetail[key]);
              } else tempFormValues[key] = serviceDetail[key];
            });
            setFormValues(tempFormValues);
          }
        },
      })
    );
  }, []);

  // Edit Functions
  const handleoptionedit = (
    data,
    modifiergpindex,
    modifieroptionindex,
    title
  ) => {
    setModifierOptionValue(data);
    setModifierIndex(modifiergpindex);
    setModifierOptionIndex(modifieroptionindex);
    setModalDetail({
      show: true,
      flag: modalFlags.modifierOption,
      title: title,
    });
    setKey(Math.random());
  };
  const handleAddonEdit = (data, modifiergpindex) => {
    setModifierDetail(data);
    setModifierIndex(modifiergpindex);
    setModalDetail({
      show: true,
      flag: modalFlags.modifierGroup,
    });
    setKey(Math.random());
  };
  const handleIntakeQuestionEdit = (data, index) => {
    setIntakeQuestValue(data);
    setModalDetail({
      show: true,
      flag: modalFlags.intakeQuestion,
    });
    setKey(Math.random());
    setIntakeQuestionIndex(index);
  };

  // Delete Functions
  const deleteEntry = (arrayList, filterIndex) => {
    return arrayList.filter((item, index) => index != filterIndex);
  };
  const handleDelete = (key, mainIndex, subIndex) => {
    if (key === ServiceConstants.intakeQuestion) {
      setintakequestion((prev) => {
        return deleteEntry(prev, mainIndex);
      });
    } else if (key === ServiceConstants.modifier) {
      setModifiers((prev) => {
        return deleteEntry(prev, mainIndex);
      });
    } else if (key === ServiceConstants.modifierOption) {
      let arrayCopy = [...modifiers];
      let selectedItem = arrayCopy[mainIndex];
      let selectedOptions = selectedItem.options;
      let updatedOptions = deleteEntry(selectedOptions, subIndex);
      let updateValues = { ...selectedItem, options: updatedOptions };
      arrayCopy[mainIndex] = updateValues;
      setModifiers(arrayCopy);
    }
  };

  // Validation and Api call 
  const validateValues = () => {
    let isValid = true;

    if (!formValues.name || formValues.name.trim() == "") {
      isValid = false;
      toast.error("Service name is Required");
    } else if (!formValues.description || formValues.description.trim() == "") {
      isValid = false;
      toast.error("Service Description is Required");
    } else if (serviceImage == null) {
      isValid = false;
      toast.error("Service Image is Required");
    } else if (isRecurring == true && recurringOptions.length == 0) {
      isValid = false;
      toast.error("Add atleast one recurrsing option");
    } else if (
      !formValues?.price ||
      formValues?.price == 0 ||
      (isNaN(formValues?.price) && formValues?.price?.trim() == "")
    ) {
      if (modifiers.length == 0) {
        isValid = false;
        toast.error("Add atleast one modifier group or increase the price");
      } else if (modifiers.length > 0) {
        let isWithoutOption = modifiers?.find(
          (item) => !item?.options || item?.options?.length == 0
        );
        if (isWithoutOption) {
          isValid = false;
          toast.error("Add atleast one Option in modifiers");
        }
      }
    } else if (modifiers?.length > 0) {
      let isWithoutOption = modifiers?.find(
        (item) => !item?.options || item?.options?.length == 0
      );
      if (isWithoutOption) {
        isValid = false;
        toast.error("Add atleast one Option in modifiers");
      }
    } else if (formValues.taxable) {
      if (!taxDetails.name || taxDetails.name.trim() == "") {
        isValid = false;
        toast.error("please enter the tax name");
      } else if (
        !taxDetails.rate ||
        (isNaN(taxDetails?.rate) && taxDetails.rate.trim() == "")
      ) {
        isValid = false;
        toast.error("please enter the tax rate");
      } else if (
        parseFloat(taxDetails.rate) <= 0 ||
        parseFloat(taxDetails.rate) >= 100
      ) {
        isValid = false;
        toast.error("Please enter a tax rate greater than 0 and less than 100");
      }
    }

    return isValid;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = await validateValues();

    if (!isValid) return;
    const apiValues = { ...formValues };

    apiValues.isRecurring = isRecurring;
    apiValues.serviceId = decodeData(serviceSlug);
    apiValues.price = formValues.price || 0;

    if (modifiers.length) {
      apiValues.modifiers = modifiers.map((modifier) => {
        return {
          ...modifier,
          options: modifier.options.map((option) => {
            if (option.allowComment) {
              return {
                ...option,
                commentPlaceholder: option.commentPlaceholder,
              };
            } else {
              return { ...option, commentPlaceholder: "" };
            }
          }),
        };
      });
    } else apiValues.modifiers = [];


    if (intakequestion.length) {
      apiValues.intakeQuestions = intakequestion;
    } else {
      apiValues.intakeQuestions = [];
    }
    if (formValues.taxable) apiValues.taxDetails = taxDetails;
    if (isRecurring) apiValues.recurringOptions = recurringOptions;
    if (serviceImage) apiValues.image = serviceImage;
    delete apiValues.slug;
    delete apiValues.categoryId;

    dispatch(
      getSubServicesUpdate({
        ...apiValues,
        cb(res) {
          if (res.status === 200) {
            navigate(routesConstants.subServices.all);
          }
        },
      })
    );
  };

  // PriceInput Ref
  const priceInputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
    };
    const inputElement = priceInputRef.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Edit Services</h1>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <FullPageloader />}
      <section className="addIndustrySection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <form onSubmit={handleSubmit}>

                <Accordion defaultActiveKey={"step1"}>

                  <Accordion.Item
                    eventKey="step1"
                    className="editService_AccordtionItem"
                  >
                    <Accordion.Header className="accorHeader">
                      <div className="edit_services_icon me-3">
                        <img
                          src={Images.ServiceDetails}
                          className=""
                        />
                      </div>
                      <div>
                        <h5 className="editSerPriTxt">Service Details</h5>
                        <p className="editSerSubTxt">Name, description, image and price</p>
                      </div>

                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label customLables">
                            Service Name
                          </label>
                          <input
                            type="text"
                            className="form-control customControl"
                            placeholder="Enter Service Name"
                            name="name"
                            value={formValues.name}
                            onChange={handleFormValuesChange}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label customLables">
                            Price($)
                          </label>
                          <input
                            type="number"
                            className="form-control customControl"
                            placeholder="Enter Service Price"
                            name="price"
                            min="0"
                            value={formValues.price}
                            onChange={handleFormValuesChange}
                            ref={priceInputRef}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label customLables">
                          Description
                        </label>
                        <textarea
                          className="form-control customControl"
                          placeholder="Write here..."
                          name="description"
                          value={formValues.description}
                          rows={6}
                          onChange={handleFormValuesChange}
                        ></textarea>
                      </div>
                      <div className="file-input mb-3">
                        <span className="d-block customLables mb-2">
                          Service Image
                        </span>
                        {serviceImage ? (
                          <div className="uploadImgOuter">
                            <div className="previewOuter">
                              <img src={serviceImage} className="imagePreview" />
                              <span
                                className="crossIcon____"
                                onClick={() => {
                                  setServiceImage(null);
                                }}
                              >
                                <img
                                  src={Images.PlusIcon_}
                                  className="plusIcon______"
                                />
                              </span>
                            </div>

                          </div>
                        ) : (
                          <>
                            {" "}
                            <input
                              type="file"
                              name="file-input"
                              id="file-input"
                              className="file-input__input"
                              onChange={handleServiceImage}
                            />
                            <label
                              className="file-input__label"
                              htmlFor="file-input"
                            >
                              <p className="dragText">
                                {" "}
                                <img src={Images.UploadImg} /> <br />
                                Drag image here or{" "}
                                <span className="browserTxt">Browse</span>
                                <br />
                                <span className="uploadCoverText">
                                  Upload cover image
                                </span>
                              </p>
                            </label>
                          </>
                        )}
                      </div>
                      <div className="tax_row">
                        <div className="d-flex gap-2 align-items-center">
                          <input
                            type="checkbox"
                            id="chargeTax"
                            checked={formValues.taxable}
                            onChange={(e) =>
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                taxable: e.target.checked,
                              }))
                            }
                          />
                          <label htmlFor="chargeTax">Charge Tax</label>
                        </div>
                        {formValues.taxable && (
                          <div className="tax">
                            <div>
                              <label>Tax Name</label>
                              <input
                                type="text"
                                value={taxDetails.name}
                                className="form-control customControl"
                                name="name"
                                placeholder="Enter tax name"
                                onChange={handleTaxValuesChange}
                              />
                            </div>
                            <div className="ml-3">
                              <label>Tax Rate</label>
                              <input
                                type="number"
                                value={taxDetails.rate}
                                className="form-control customControl"
                                placeholder="Enter the rate of tax (0-100)"
                                name="rate"
                                onChange={handleTaxValuesChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="step4"
                    className="editService_AccordtionItem"
                  >
                    <Accordion.Header>
                      <div className="edit_services_icon me-3">
                        <img
                          src={Images.ServiceModifiers}
                          className=""
                        />
                      </div>
                      <div>
                        <h5 className="editSerPriTxt"> <span className="me-2">Service Modifiers</span> 
                        {modifiers?.length ? <span className="subHeading">{modifiers?.length || 0} Modifier Groups</span> : ""}</h5>
                        <p className="editSerSubTxt">Add selectable options that can be used to customize this service's price</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row px-4">
                        {/* added addOnTypes   list */}

                        {modifiers?.length > 0 &&
                          modifiers?.map((item, modifiergpindex) => {
                            return (
                              <React.Fragment key={modifiergpindex}>
                                <div className="col-md-12 mb-3">
                                  <div className="smallLawnOuter">
                                    <div className="smallLawnData">
                                      <div className="modifier_row">
                                        <h4 className="smallLawnHeading m-2">
                                          {item?.title}
                                        </h4>
                                        <div className="d-flex gap-2">
                                          <button
                                            type="button"
                                            className=" addOptionbtn"
                                            onClick={() => {
                                              setModalDetail({
                                                show: true,
                                                flag: modalFlags.modifierOption,
                                                data: item,
                                                modifiergpindex:
                                                  modifiergpindex,
                                              });
                                              setModifierIndex(modifiergpindex);
                                              setKey(Math.random());
                                            }}
                                          >
                                            Add Options
                                          </button>
                                          <img
                                            src={Images.GreenEdit}
                                            className=""
                                            width={28}
                                            onClick={() => {
                                              handleAddonEdit(
                                                item,
                                                modifiergpindex
                                              );
                                            }}
                                          />
                                          <img
                                            src={Images.GreenCross}
                                            width={28}
                                            onClick={() => {
                                              handleDelete(
                                                ServiceConstants.modifier,
                                                modifiergpindex
                                              );
                                            }}
                                            className=""
                                          />
                                        </div>
                                      </div>

                                      {item?.options?.map(
                                        (option, modifieroptionindex) => {
                                          return (
                                            <div
                                              className=" mx-3 my-2 options-edit"
                                              key={modifieroptionindex}
                                            >
                                              <h4 className="gray mb-1">
                                                {option?.title}
                                              </h4>
                                              <div className="d-flex gap-2">
                                                <img
                                                  src={Images.GreenEdit}
                                                  className="optionsediticon_"
                                                  onClick={() => {
                                                    handleoptionedit(
                                                      option,
                                                      modifiergpindex,
                                                      modifieroptionindex,
                                                      option?.title
                                                    );
                                                  }}
                                                />
                                                <img
                                                  src={Images.GreenCross}
                                                  onClick={() => {
                                                    handleDelete(
                                                      ServiceConstants.modifierOption,
                                                      modifiergpindex,
                                                      modifieroptionindex
                                                    );
                                                  }}
                                                  className=""
                                                />
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div>
                      <div className="intake-btn">
                        <button
                          type="button"
                          className="cancelBtn recurringbtn mt-4"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: modalFlags.modifierGroup,
                            });
                            setKey(Math.random());
                          }}
                        >
                          New Modifier Group
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="step5"
                    className="editService_AccordtionItem"
                  >
                    <Accordion.Header>
                      <div className="edit_services_icon me-3">
                        <img
                          src={Images.IntakeQuestion}
                          className=""
                        />

                      </div>
                      <div>
                        <h5 className="editSerPriTxt"> <span className="me-2"> Intake Questions</span> {intakequestion?.length ? <span className="subHeading">{intakequestion?.length || 0} Intake Question</span> : ""}</h5>
                        <p className="editSerSubTxt">Add custom form fields to collect additional info</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row px-4">
                        {/* added addOnTypes   list */}
                        {intakequestion.length > 0 &&
                          intakequestion?.map((item, index) => {
                            return (
                              <React.Fragment>
                                <div className="col-md-12 mb-3">
                                  <div className="smallLawnOuter">
                                    <img
                                      src={Images.PlusIcon_}
                                      onClick={() => {
                                        handleDelete(
                                          ServiceConstants.intakeQuestion,
                                          index
                                        );
                                      }}
                                      className="plusIcon_"
                                    />
                                    <img
                                      src={Images.EditIcon}
                                      className="editIcon_"
                                      onClick={() => {
                                        handleIntakeQuestionEdit(item, index);
                                      }}
                                    />
                                    <div className="smallLawnData">
                                      <h4 className="smallLawnHeading mb-1">
                                        {item?.question}
                                      </h4>
                                      <p className="mb-2">
                                        {item?.description}
                                      </p>
                                      {/* <h6>{item.description}</h6> */}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div>
                      <div className="intake-btn">
                        <button
                          type="button"
                          className="cancelBtn recurringbtn mt-4"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: modalFlags.intakeQuestion,
                            });
                            setKey(Math.random());
                          }}
                        >
                          New Intake Question
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="step6"
                    className="editService_AccordtionItem"
                  >
                    <Accordion.Header>
                      <div className="edit_services_icon me-3">
                        <img
                          src={Images.TeamRequirement}
                          className=""
                        />

                      </div>
                      <div>
                        <h5 className="editSerPriTxt"> <span className="me-2"> Team Requirements & Assignment Options Intake Questions</span></h5>
                        <p className="editSerSubTxt">Add required skills and customize how jobs should be assigned for this service</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row px-4">
                        <div className="radio-option">
                          <input
                            type="radio"
                            id="automatically"
                            name="assignment"
                            value="automatically"
                            checked={assignmentType === "automatically"}
                            onChange={handleOptionChange}
                          />
                          <label htmlFor="manual">Automatically</label>
                          <p>
                            Offers jobs for this service to all available
                            providers who possess the necessary skill tags
                            untill the required number of providers needed
                            accept
                          </p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="step2"
                    className="editService_AccordtionItem"
                  >
                    <Accordion.Header>
                      <div className="edit_services_icon me-3">
                        <img
                          src={Images.Recurring}
                          className=""
                        />


                      </div>
                      <div>
                        <h5 className="editSerPriTxt"> <span className="me-2">  Recurring Options</span> {recurringOptions?.length ? <span className="subHeading"> {recurringOptions?.length || 0} Recurring Question</span> : ""}</h5>
                        <p className="editSerSubTxt">Give customers the option to book this service as a recurring appoitnment</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-3">
                        <span className="customLables d-block mb-3">
                          Service Type
                        </span>
                        <div className="d-inline-block me-4">
                          <input
                            type="radio"
                            id="test1"
                            name="radio-group"
                            checked={!isRecurring}
                            readOnly
                            onClick={() => {
                              setIsRecurring(false);
                            }}
                          />
                          <label htmlFor="test1">One Time</label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            id="test2"
                            name="radio-group"
                            checked={isRecurring}
                            readOnly
                            onClick={() => {
                              setIsRecurring(true);
                              if (recurringOptions?.length === 0) {
                                setModalDetail({
                                  show: true,
                                  flag: modalFlags.recurring,
                                });
                                setKey(Math.random());
                              }
                            }}
                          />
                          <label htmlFor="test2">Recurring</label>
                        </div>
                      </div>
                      {isRecurring && (
                        <div className="addSubServiceOuter my-4">
                          <table className="addServiceTable">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Frequency</th>
                                <th>Discount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {recurringOptions.map((item, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{item?.title}</td>
                                      <td>{item?.frequency}</td>
                                      <td>{item?.discountPercentage}% off</td>
                                      <td className="d-flex gap-2">
                                        <img
                                          className="cursor-pointer"
                                          src={Images.EditIcon}
                                          onClick={() => {
                                            recurringEdit(item, i);
                                          }}
                                        />
                                        <img
                                          className="cursor-pointer"
                                          src={Images.DeleteIcon}
                                          onClick={() => {
                                            handlerecurringOptionsRemove(i);
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                          <button
                            type="button"
                            className="cancelBtn recurringbtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: modalFlags.recurring,
                              });
                              setKey(Math.random());
                            }}
                          >
                            Add more recurring
                          </button>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

                <div className="mt-4">
                  <button
                    className="cancelBtn me-4"
                    type="button"
                    onClick={(e) => {
                      navigate(routesConstants.subServices.all);
                    }}
                  >
                    CANCEL
                  </button>
                  <button className="addBtn" style={{ maxWidth: "187px" }}>
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={true}
            bigModal={modalDetail?.flag === "viewIndustry"}
            mediumWidth={false}
            className={
              modalDetail.flag === modalFlags.recurring
                ? "commonWidth customContent"
                : ""
            }
            ids={
              modalDetail.flag === modalFlags.recurring ? "createRecruting" : ""
            }
            child={
              modalDetail.flag === modalFlags.recurring ? (
                <CreateRecrutingModal
                  editData={recurringEditData}
                  addNewRecursion={submitRecurring}
                  reset={resetRecurringData}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === modalFlags.modifierGroup ? (
                <ServiceModifiers
                  modifierValues={modifierDetail}
                  setModifierValues={setModifierDetail}
                  modifierValueChange={handleModifierChange}
                  close={() => handleOnCloseModal()}
                  reset={resetModifierDetails}
                  submitModifier={modifierAdd}
                  modifierIndex={modifierIndex}
                />
              ) : modalDetail.flag === modalFlags.intakeQuestion ? (
                <IntakeQuestions
                  values={intakeQuesValue}
                  submit={intakeQuestionAdd}
                  reset={resetIntakeQuestions}
                  setValues={setIntakeQuestValue}
                  handleChange={handleIntakeChange}
                  close={() => handleOnCloseModal()}
                  intakeQuestionIndex={intakeQuestionIndex}
                />
              ) : modalDetail.flag === modalFlags.modifierOption ? (
                <ServiceModifiersOptions
                  submit={modifierOptionAdd}
                  reset={resetModifierOptions}
                  handleChange={handleModifierOptionChange}
                  modifierOptionValue={modifierOptionValue}
                  setModifierOptionValue={setModifierOptionValue}
                  close={() => handleOnCloseModal()}
                  modifierOptionIndex={modifierOptionIndex}
                />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === modalFlags.recurring ? (
                <>
                  <h2 className="modal_Heading">Recurring Option</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel"></p>
                </>
              ) : modalDetail.flag === modalFlags.intakeQuestion ? (
                <div className="d-flex justify-content-center mx-auto">
                  <h2 className="modal_Heading">Intake Questions</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel"></p>
                </div>
              ) : modalDetail.flag === modalFlags.modifierGroup ? (
                <div className="d-flex justify-content-center mx-auto">
                  <h2 className="modal_Heading">Modifier Group</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel"></p>
                </div>
              ) : modalDetail.flag === modalFlags.modifierOption ? (
                <div className="d-flex justify-content-center mx-auto">
                  <h2 className="modal_Heading">Modifiers Options</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel"></p>
                </div>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </div>
      </section >
    </div >
  );
}