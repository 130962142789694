import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";

function ServiceModifiersOptions(props) {
  const {
    reset,
    submit,
    close,
    handleChange,
    modifierOptionValue,
    modifierOptionIndex,
    setModifierOptionValue,
  } = props;

  const validate = () => {
    if (modifierOptionValue?.title.trim() === "") {
      toast.error("Please enter option name");
      return;
    }
    //  else if (modifierOptionValue?.description.trim() === "") {
    //   toast.error("Please enter the options description");
    //   return;
    // } 
    else if (modifierOptionValue?.price === "") {
      toast.error("Please enter price");
      return;
    } else if (
      modifierOptionValue?.allowComment &&
      (!modifierOptionValue?.commentPlaceholder || modifierOptionValue.commentPlaceholder.trim() === "")
    ) {
      toast.error("Please enter comment placeholder");
      return;
    } else {
      submit();
    }
  };
  const priceInputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
    };

    const inputElement = priceInputRef.current;

    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <>
      <div className="row" id="addOnRow">
        <div className="mb-3 col-md-6">
          <label className="form-label customLables">Name</label>
          <input
            type="text"
            className="form-control customControl"
            placeholder="Enter Name"
            name="title"
            value={modifierOptionValue?.title}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 col-md-6">
          <label className="form-label customLables">Price</label>
          <div className="d-flex align-items-center">
            <span className="dollar">$</span>
            <input
              type="number"
              className="form-control customControl"
              placeholder="Enter Price"
              min="0"
              name="price"
              value={modifierOptionValue?.price}
              onChange={handleChange}
              ref={priceInputRef}
            />
          </div>
        </div>
        <div className="mb-3 col-md-12">
          <label className="form-label customLables">Description</label>
          <textarea
            className="form-control customControl"
            rows={6}
            placeholder="Write here..."
            name="description"
            value={modifierOptionValue?.description}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="d-flex gap-2 align-items-center mb-2">
          <input
            type="checkbox"
            id="customercheck"
            checked={modifierOptionValue.allowComment}
            onChange={(e) =>
              setModifierOptionValue((prevValues) => ({
                ...prevValues,
                allowComment: e.target.checked,
              }))
            }
          />
          <label htmlFor="customercheck">Allow customers to add a note</label>
        </div>

        {modifierOptionValue.allowComment && (
          <div className="mb-3 col-md-12">
            <label className="form-label customLables">
              Comment Placeholder
            </label>
            <textarea
              className="form-control customControl"
              rows={4}
              placeholder="Write here..."
              name="commentPlaceholder"
              value={modifierOptionValue?.commentPlaceholder}
              onChange={handleChange}
            ></textarea>
          </div>
        )}

        <button
          className="cancelBtn me-4"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            reset();
            close();
          }}
        >
          CANCEL
        </button>

        <button className="addBtn" type="button" onClick={validate}>
          {modifierOptionIndex !== null ? "Update" : "Add"}
        </button>
      </div>
    </>
  );
}

export default ServiceModifiersOptions;
