import React, { useEffect, useState } from "react";
import { getAllIndustry, deleteIndustry } from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useAuthSelector } from "../../redux/selector/auth";
import Swal from "sweetalert2";
import { encodeData } from "../../utilities/helper";

const ManageOutfit = () => {
  const dispatch = useDispatch();
  const [serviceList, setServiceList] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [loading, setLoading] = useState(true);
  const authData = useAuthSelector();
  const navigate = useNavigate();
  const deleteIndustryAction = (id) => {
    const params = {
      id,
    };
    Swal.fire({
      title: "Do you want to delete the Industry?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: 'green-btn',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(
          deleteIndustry({
            ...params,
            cb(res) {
              if (res.status === 200) {
                getAllIndustryAction();
              }
            },
          })
        );
      }
    });
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };
  useEffect(() => {
    getAllIndustryAction();
  }, [ search, currentPage]);

  const getAllIndustryAction = (page = currentPage) => {
    let params = {
      limit: 10,
      page: page,
    };
    if (search !== "") {
      params.search = search;
    }
    dispatch(
      getAllIndustry({
        ...params,
        cb(res) {
          setServiceList((prev) => {
            let newArray = res?.data?.data?.services;
            setLoading(false);
            return newArray;
          });
          setPageCount(res?.data?.data?.total_pages);
        },
      })
    );
  };

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Service</h1>
            </div>
            <div className="col-sm-6 text-end">
              <button
                className="editcontentBtn"
                onClick={() => {
                  navigate("/add-service");
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <section className="mb-2">
        <div className="container-fluid">
          <div className="row">
            <div className=" mt-4 userFilter">
              <div className="d-flex commonFilterHeader  userSearch ">
                <input
                  type="search"
                  className="filterSearch"
                  placeholder="search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content commonBox_  userlistbox">
        <div className="container-fluid">
          <table className="usertable">
            <tr className="tableborder">
              <th>Name</th>
              <th>Number Of Services</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
            {authData.loading || loading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center">
                      <div className="spinner-grow" role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {serviceList && serviceList.length > 0 && (
                  <>
                    {serviceList?.map((data, index) => (
                      <tr key={`industries_${index}`} className="cursor">
                        <td>{data?.name ?? "---"}</td>

                        <td className="text-lowercase">
                          {data?.types?.length}
                        </td>
                        <td>{moment(data?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>
                          <div className="dropdown filterDropdown">
                            <button
                              className="modalbtn ms-3 barbtn"
                              onClick={() => {
                                navigate(
                                  `/edit-service/${encodeData(data._id)}`,
                                  {
                                    state: {
                                      edit: true,
                                      data: data,
                                      industryId: data._id,
                                    },
                                  }
                                );
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                            <button
                              className="modalbtn ms-3 barbtn"
                              onClick={() => {
                                deleteIndustryAction(data?._id);
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                            <button
                              className="modalbtn ms-3 barbtn"
                              onClick={() => {
                                navigate(
                                  `/view-service/${encodeData(data._id)}`,
                                  {
                                    state: {
                                      edit: false,
                                      industryData: data,
                                      industryId: data._id,
                                    },
                                  }
                                );
                              }}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </table>
          {(!serviceList || serviceList.length === 0) && (
            <div className="d-flex justify-content-center my-4">
              No Data Found
            </div>
          )}

          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {serviceList?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageOutfit;
